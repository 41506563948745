import React from "react";
import Link from "@mui/material/Link";
import { useHistory } from "react-router";
import { FormattedMessage, useIntl } from "react-intl";
import { ThemeProvider } from "@mui/material";
import Theme_WIP from "../../../styles/themes/main/Theme_WIP";
import Text from "../../../visualComponents/Text";

export default function ActivityLeftCircleCard({activity, source, color}) {
  const history = useHistory();
  const intl = useIntl();

  const getMessage = () => {
    if (activity.activity.messages === "Circle update activity") {
      return (
        intl.formatMessage({ id: "MadeYouCircleManagerOfTheCircle" }) + " "
      );
    } else if (activity.activity.messages === "Circle Closed") {
      return intl.formatMessage({ id: "TheCircle" }) + " ";
    } else if (activity.activity.messages === "Accepted Circle Invitation") {
      return (
        intl.formatMessage({ id: "AcceptedTheInvitationOfTheCircle" }) + " "
      );
    } else if (activity.activity.messages === "Circle Invitation Declined") {
      return (
        intl.formatMessage({ id: "DeclinedTheInvitationOfTheCircle" }) + " "
      );
    } else if (activity.activity.messages === "Left the Circle") {
      return intl.formatMessage({ id: "LeftTheCircle" }) + " ";
    } else if (activity.activity.messages === "Removed from Circle") {
      return intl.formatMessage({ id: "Removed_From_Circle1" })
    }
  };
  const navigateToCircleDetail = () => {
    history.push("/dashboard/circleDetails/?id=" + activity.activity.circleId, {
      selectedCircleId: activity.activity.circleId,
      selectedTab: 3,
      selectedPostID: activity.activity.activitySourceId,
    });
  };

  if(source == "menu")
    return undefined;
  if(source == "canCollapse")
    return undefined;
  return (
    <ThemeProvider theme={Theme_WIP}>
      <Text color={color??"background"}>
        {activity?.activity?.messages !== "Circle Closed" &&
          <>
            {getMessage()}
            <Link
              color="inherit"
              component="button"
              underline="always"
              onClick={navigateToCircleDetail}
            >
              {activity.activity.circleName}
            </Link>
            {activity?.activity?.messages === "Removed from Circle" && <FormattedMessage id="Removed_From_Circle2" />}
          </>
        }
        {activity?.activity?.messages === "Circle Closed" &&
          <>
            {<FormattedMessage id="TheCircle" />}
            {" "}{activity.activity.circleName.toUpperCase()}{" "}
            {<FormattedMessage id="ActivityLeftCircleCard_Description" />}
          </>
        }
      </Text>
    </ThemeProvider>
  );
}
