import React from "react";
import {
  MenuItem,
} from "@material-ui/core";
import { useHistory } from "react-router";
import { FormattedMessage } from "react-intl";
import Text from "../../../visualComponents/Text";
import { ThemeProvider } from "@mui/material";
import Theme_WIP from "../../../styles/themes/main/Theme_WIP";


export default function ActivityCircleContactManager({activity, source, isLess, color}) {
  const history = useHistory();

  const navigateToMessage = () => {
    history.push("/sendMessage", {
      activity: activity,
    });
  };

  if(source == "canCollapse")
    return (activity?.activity?.messages?.length ?? 0) > 150;
  if(source == "menu")
    return <MenuItem onClick={navigateToMessage}> <FormattedMessage id={"Message"} /> </MenuItem>;
  else
    return (
      <ThemeProvider theme={Theme_WIP}>
        {activity?.activity?.messages &&
          <Text item xs={12} color={color??"background"} display="inline">
            {!isLess
              ? activity?.activity?.messages
              : activity?.activity?.messages?.substring(0, 150) +"..."}
          </Text>
        }
      </ThemeProvider>
  );
}
