import { AppBar, Chip, Container, Grid, Hidden, IconButton, Menu, MenuItem, ThemeProvider, Toolbar, Typography } from '@mui/material';
import React from 'react';
import { withRouter, useHistory, NavLink } from 'react-router-dom';
import styles from "../styles/component/NavigationbarStyle";
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { Person, Settings, HelpOutline } from '@material-ui/icons';
import MenuIcon from '@material-ui/icons/Menu';
import PropTypes from "prop-types";
import { ButtonDarkBlue } from './Buttons/ButtonDarkBlue';
import { Button, Stack } from '@mui/material';
import { signoutRedirect } from './IdentityServer/userService';
import { Box } from '@mui/system';
import HamburgerView from './HamburgerView';
import { loginType } from '../apiServices/Constants';
import { useDispatch } from 'react-redux';
import { updateBrandColor } from '../apiServices/Redux/Reducer';
import theme from '../styles/container/Theme';
import { SUPPORT_PAGE_URL } from '../Config';
import { ClearItems } from '../apiServices/CommonMethods';
import { useGlobalContext } from '../apiServices/Providers/AppGlobalStateProvider';
import Modal from './modals/Modal';
import Text from '../visualComponents/Text';
import Theme_WIP, { ThemeConstants } from '../styles/themes/main/Theme_WIP';
import Image from './Media/Image';
import {ReactComponent as InviteIcon} from "../svg/HeaderNavigationEtc/InviteIcon.svg";
import {ReactComponent as DownArrow} from "../svg/HeaderNavigationEtc/DownArrowIcon.svg";
import { useSubInfo } from '../objects/Subscriptions';
import { useHasCompanyAccess } from '../apiServices/Queries/Profiles/MetaData';
import MessageBar from './Layout/MessagesBar';
import BottomBar from '../components/BottomBar';

const useStyles = makeStyles(styles);

const Navigationbar = (props) => {
    const dispatch = useDispatch()
    const globals = useGlobalContext();
    const userRole = globals.state.loggedInUser.role;
    const sub     = useSubInfo();
    const classes = useStyles();
    const history = useHistory()
    const [modal   , setModal] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const subInfo = useSubInfo();
    const companyAccess = useHasCompanyAccess();
    const acceptedInvites = globals.state.loggedInUser.acceptedInvites;

    const handleClick = (event) => {
        setAnchorEl(anchorEl? null : event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const navigateToProfile = () => {
        dispatch(updateBrandColor(theme.palette.primary.main))
        setAnchorEl(null);
        history.push('/dashboard/profile/', { selectedUserId: "", });
    };
    const logoutAction = () => {
        if (localStorage.getItem("loginType") === loginType.idserver) {
            // localStorage.clear()
            signoutRedirect()
        } else {
            ClearItems();
            history.push("/Registration")
        }
    };
    const navigateToInvite = () => {
        history.push('/dashboard/profile/', { tab: 3, });
    }
    const navigateToIntroduction = () => {
        history.push("/introduction")
    }
    const [openHamburger, setOpenHamburger] = React.useState(false);

    const handleOpen = () => {
        // console.log("hambergurAction", openHamburger)
        setOpenHamburger(!openHamburger);
    };
    const closeHambergur = () => {
        setOpenHamburger(false);
    };
    const navigateToSettings = () => {
        dispatch(updateBrandColor(theme.palette.primary.main))
        history.push("/dashboard/settings")
    }
    const navigateToHelp = () => {
        dispatch(updateBrandColor(theme.palette.primary.main))
        window.open(SUPPORT_PAGE_URL, "_blank");
    }

    return (<ThemeProvider theme={Theme_WIP}>
        <Modal open={modal} onClose={() => setModal(false)}>
            <Text variant="h1"    color="background" item xs={12} align="center" id="Credits" bold/>
            <Text variant="body1" color="background" item xs={12} align="center" id="CreditDesc"/>
            <Grid container item xs={12} justifyContent="center">
                <Button variant="contained" onClick={() => history.push("/getpremium")}>
                    <FormattedMessage id="FreemiumCTA"/></Button></Grid>
        </Modal>
        <BottomBar/>
        <AppBar position="fixed" sx={{
            marginTop:  (sub.current?.LowerCaseSubType == sub.all.initialTrial.LowerCaseSubType 
                     || (sub?.current?.LowerCaseSubType == sub.all.freemium.LowerCaseSubType && !acceptedInvites))
                     && !localStorage.getItem("acceptedInvites") ? "30px" : 0,
            padding: "10px 0px",
            paddingRight: "0px !important",
            width: '100%',
            height: "50px",
            backgroundColor: Theme_WIP.palette.white.main,
            boxShadow: "0px 4px 16px 0px rgba(0, 57, 77, 0.10)",
            zIndex: 1600
        }}>
            <Hidden mdUp>
                <HamburgerView openStatus={openHamburger} hambergurAction={handleOpen} />
            </Hidden>
            <Grid px={{xs: 4, md: ThemeConstants.custom.spacings.lg}} container gap={ThemeConstants.custom.spacings.md} justifyContent="end" maxHeight="30px" sx={{cursor: "pointer"}}>
                <Hidden mdUp>
                    {/* <Grid item col>
                        <IconButton sx={{p: "5px"}} aria-label="open drawer" onClick={handleOpen}>
                            <MenuIcon />
                        </IconButton >
                    </Grid> */}
                    <Grid item>
                        <NavLink
                            to={"/dashboard/masters"}
                            className={classes.logo}
                            activeClassName="active"
                            onClick={closeHambergur}
                        >
                            <img src={"/assets/img/mm_logo_petrol blue.svg"} alt={"mastermatch_logo"} style={{ height: "15px", marginTop: "10px"}} />
                        </NavLink>
                    </Grid>
                </Hidden>
                <Hidden mdDown>
                    <Grid item>
                        <NavLink to={"/dashboard/masters"} activeClassName="active">
                            <img src={"/assets/img/mastermatch_logo_petrol_blue_large.svg"} alt={"mastermatch_logo"} style={{height: "20px", marginTop: "5px"}}/>
                        </NavLink>
                    </Grid>
                </Hidden>

                <Grid item xs />

                <Grid item>
                    <IconButton onClick={navigateToIntroduction} sx={{p: 0}}>
                        <InviteIcon height="30px" width="30px" style={{padding: "5px"}}/>
                    </IconButton >
                </Grid>
                <Grid container item onClick={handleClick} width="fit-content" gap={ThemeConstants.custom.spacings.xs}>
                    <Image
                        src={globals.state?.loggedInUser?.profile?.profilePictureUrl ?? "/assets/img/userProfile.png"}
                        alt="profile picture"
                        style={{
                            height: "30px",
                            width: "30px",
                            borderRadius: Theme_WIP.custom.borderRadius.bottomLeft,
                            aspectRatio: "1 / 1"
                        }}
                    />
                    <IconButton sx={{p: 0}}>
                        <DownArrow height="30px" width="25px" style={{padding: "5px", rotate: open? "180deg" : undefined}}/>
                    </IconButton >
                    <Menu
                        PaperProps={{
                            sx:{
                                width: "250px",
                                '@media (max-width: 599px)': {
                                    width: "100vw !important",
                                    maxWidth: "100vw",
                                    top: "50px !important",
                                    bottom: "0px",
                                    left: "0px !important"
                                }
                            }
                        }}
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        onClick={handleClose}
                    >
                        <Grid container direction="column" gap="20px" p="20px" px={ThemeConstants.custom.spacings.lg}>
                            <Grid container item xs={12} gap={ThemeConstants.custom.spacings.sm}>
                                <Image
                                    src={globals.state?.loggedInUser?.profile?.profilePictureUrl ?? "/assets/img/userProfile.png"}
                                    alt="profile picture"
                                    style={{
                                        height: "40px",
                                        width: "40px",
                                        borderRadius: Theme_WIP.custom.borderRadius.bottomLeft,
                                        aspectRatio: "1 / 1"
                                    }}
                                />
                                <Grid container item maxWidth="140px" sx={{overflowX: "hidden"}}>
                                    <Text bold variant="body1" sx={{whiteSpace: "nowrap"}}>{globals.state?.loggedInUser?.profile?.givenName} {globals.state?.loggedInUser?.profile?.familyName}</Text>
                                    {subInfo.current?.identifyer && <Chip
                                        label={<FormattedMessage id={subInfo.current?.identifyer}/>}
                                        size="small"
                                        color="background"
                                    />}
                                </Grid>
                            </Grid>
                            
                            {userRole && <MenuItem sx={{p:0}} onClick={() => setModal(true)}>
                                <Text color="background" variant="body1"><FormattedMessage id="CreditsUsed" /> {userRole.responses} / {userRole.maxResponses}</Text>
                            </MenuItem>}
                            <Button onClick={() => history.push("/getpremium")} sx={{mt: "-10px"}} variant="contained" color="lightPetrol"><FormattedMessage id="switchToPremium"/></Button>

                            <MenuItem sx={{p:0}} onClick={navigateToProfile}>
                                <Text color="background" variant="body1" id="Header_menu.My_profile" />
                            </MenuItem>
                            <MenuItem sx={{p:0}} onClick={navigateToSettings}>
                                <Text color="background" variant="body1" id="accountSettings" />
                            </MenuItem>
                            <MenuItem sx={{p:0, display: {xs: undefined, md: "none"}}} onClick={navigateToHelp}>
                                <Text color="background" variant="body1" id="helpDesk" />
                            </MenuItem>
                            {companyAccess.accessToCompanies &&
                            <MenuItem sx={{p:0}} onClick={() => history.push("/dashboard/companies")}>
                                <Text color="background" variant="body1" id="Side_menu.Companies" />
                            </MenuItem>
                            }
                            <div style={{borderTop: "1px solid " + ThemeConstants.palette.lighterPetrol.main}}></div>
                            <MenuItem sx={{p:0}} onClick={logoutAction}>
                                <Text color="background" variant="body1" id="Header_menu.Log_out" />
                            </MenuItem>
                        </Grid>
                    </Menu>
                </Grid>
            </Grid>

        </AppBar>
        <MessageBar/>
        </ThemeProvider>
    );
}
Navigationbar.prototype = {
    hambergurAction: PropTypes.func
};

export default withRouter(Navigationbar);