import { Accordion as Ac, AccordionDetails, AccordionSummary } from "@mui/material";
//import {ReactComponent as ArrowDown} from "../svg/ui/arrowDown.svg";
import {ReactComponent as ArrowDown} from "../svg/HeaderNavigationEtc/DownArrowIcon.svg";
import Text from "./Text";

export default function Accordion({summary, details, color, values}) {
    return <Ac>
        <AccordionSummary
        expandIcon={<ArrowDown />}
        >
            <Text id={summary} bold color={color ?? "background"} values={values}/>
        </AccordionSummary>
        <AccordionDetails>
            <Text id={details} color={color ?? "background"} values={values}/>
        </AccordionDetails>
    </Ac>
    ;
}