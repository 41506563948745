import React from "react";
import Link from "@mui/material/Link";
import { useHistory } from "react-router";
import { FormattedMessage, useIntl } from "react-intl";
import { ThemeProvider } from "@mui/material";
import Theme_WIP from "../../../styles/themes/main/Theme_WIP";
import Text from "../../../visualComponents/Text";

export default function ActivityMakeEventManager({activity, source, color}) {
  const intl = useIntl();
  const history = useHistory();

  const getMessage = () => {
    if (activity.activity.messages === "Made you event manager of the event") {
      return intl.formatMessage({ id: "activity_event_manager_message" });
    } else if (activity.activity.messages === "Leave from Event") {
      return intl.formatMessage({ id: "activity_event_not_attending" });
    } else if (activity.activity.messages === "Leave/Removed from Event") {
      return intl.formatMessage({ id: "activity_event_remove_event" });
    } else if (activity.activity.messages === "Accepted Event Invitation") {
      return intl.formatMessage({ id: "activity_event_invite_accept1" });
    } else if (activity.activity.messages === "Event Invitation Declined") {
      return intl.formatMessage({ id: "DeclinedTheInvitationOfTheEvent" });
    }
  };
  const navigateToEventDetail = () => {
    history.push("/dashboard/eventDetails/?id=" + activity.activity.eventId, {
      selectedEventId: activity.activity.eventId,
    });
  };

  if(source == "menu")
    return undefined;
  if(source == "canCollapse")
    return undefined;
  return (
    <ThemeProvider theme={Theme_WIP}>
      <Text color={color??"background"}>
        {activity?.activity?.messages !== "Event Closed" && activity?.activity?.messages !== "Upcoming Event Reminder" &&
          <>
            {getMessage()}
            <Link
              color="inherit"
              component = "button"
              underline = "always"
              onClick={navigateToEventDetail}
            >
              {activity.activity.eventName}
            </Link>
            {activity?.activity?.messages === "Accepted Event Invitation" && <FormattedMessage id="activity_event_invite_accept2" />}
            {activity?.activity?.messages === "Leave/Removed from Event"  && <FormattedMessage id="activity_event_remove_event_description" />}
          </>
        }
        {activity?.activity?.messages === "Upcoming Event Reminder" &&
          <>
            <FormattedMessage id="Upcoming_Event_Description1" />
            <Link
              color="inherit"
              component = "button"
              underline = "always"
              onClick={navigateToEventDetail}
            >
              {activity.activity.eventName}
            </Link>
            <FormattedMessage id="Upcoming_Event_Description2" values={{
                link: (<a href="/dashboard/requests">{intl.formatMessage({ id: "Upcoming_Event_Description2_link"})}</a>)
            }}/>
          </>
        }
        {activity?.activity?.messages === "Event Closed" &&
          <>
            <FormattedMessage id="TheEvent" />
            {" "}<b>{activity.activity.eventName}</b>{" "}
            <FormattedMessage id="ActivityMakeEventManager_Description" />
          </>
        }
      </Text>
    </ThemeProvider>
  );
}
