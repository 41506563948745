import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@mui/material/Grid";
import Paper from "@material-ui/core/Paper";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { FormattedMessage, useIntl } from "react-intl";
import Styles from "../../styles/component/EventTabs/EventVisitorsStyle";
import VisitorCard from "../Cards/VisitorCard";
import { GET_EVENT_VISITORS } from "../../apiServices/Queries";
import { useQuery } from "@apollo/client";
import { Alert, CircularProgress, Stack } from "@mui/material";
import Accordian from "../../components/Accordian";
import { Waypoint } from "react-waypoint";
import VisitorAutosearchPage from "../VisitorAutosearchPage";
import { useHistory } from "react-router-dom";
import { userRoles } from "../../apiServices/Constants";
import GoTo from "../../apiServices/Navigation/NavigateWithReload";
import { useGlobalContext } from "../../apiServices/Providers/AppGlobalStateProvider";
import { ThemeConstants } from "../../styles/themes/main/Theme_WIP";
import Accordion from "../../visualComponents/Accordion";

const useStyles = makeStyles(Styles);

export default function EventVisitor(props) {
  const globals  = useGlobalContext();
  const userRole = globals.state.loggedInUser.role.role;
  const userId   = globals.state.loggedInUser.userId;
  const classes = useStyles();
  const intl = useIntl();
  const history = useHistory();
  const [members, setMembers] = React.useState([]);
  const [eventdata, setEventData] = React.useState(props?.eventDetails);
  const [isShowFilter, setIsShowFilter] = React.useState(false);
  let [input, setInput] = React.useState({
    location: null,
    industries: null,
    skills: null,
    maxDistance: null,
    collaborationType: null,
    businessType: null,
    searchInput: null,
    pageNumber: 1,
  });
  const getVariable = () => {
    const variable = {
      id: eventdata.id,
      loginProfileId: userId,
      pageNumber: input["pageNumber"],
      pageSize: parseInt(50),
      searchInput: input["searchInput"],
      skills: input["skills"],
      industries: input["industries"],
      collaborationType: input["collaborationType"],
      businessTypes: input["businessType"],
      location: input["location"],
      maxDistance: input["maxDistance"],
      participantScopeDesc: eventdata.participantScopeDesc,
    };
    // console.log("vistorvariable", variable);
    return variable;
  };
  useEffect(() => {
    if (typeof props.eventDetails !== "undefined") {
      setEventData(props.eventDetails);
    }
  }, [props]);
  // console.log("eventdata", eventdata);
  const { loading, fetchMore, error } = useQuery(
    GET_EVENT_VISITORS,
    {
      variables: getVariable(),
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "network-only",
      onCompleted: data => {
        if (typeof data !== "undefined" && data.eventVisitors.errorCode === 0) {
          const response = data.eventVisitors.result;
          // console.log("VisitorsList", response);
          // console.log("pageNumber", input["pageNumber"]);
          if (eventdata.loginUserStatus !== "Manager") {
            // console.log("loginUserStatus", "Manager");
            if (input["pageNumber"] === 1) {
              let visitorList = response.filter(list => {
                return (
                  list.visitorStatus === "Attending" ||
                  list.visitorStatus === "Manager" ||
                  list.visitorStatus === "Deelnemer"
                );
              });
              setMembers(visitorList);
            } else {
              if (data.eventVisitors.result.length) {
                let visitorList = response.filter(list => {
                  return (
                    list.visitorStatus === "Attending" ||
                    list.visitorStatus === "Manager" ||
                    list.visitorStatus === "Deelnemer"
                  );
                });
                setMembers(prevState => [...prevState, ...visitorList]);
              }
            }
          } else {
            // console.log("pgno", input["pageNumber"]);
            if (input["pageNumber"] === 1) {
              setMembers(response);
            } else {
              if (response.length) {
                setMembers(prevState => [...prevState, ...response]);
              }
            }
          }
        } else {
          setMembers([]);
        }
      },
    }
  );
  useEffect(() => fetchMore({variables: getVariable()}), [input]);

  const showFilter = () => {
    // setMembers([]);
    setInput(prevState => ({
      ...prevState,
      pageNumber: parseInt(1),
    }));
    setIsShowFilter(!isShowFilter);
  };
  const navigateToGetPremium = () => {
    GoTo(history, "/GetPremium");
  };
  const filterAction = inputs => {
    // console.log("inputs", inputs);
    if (userRole === userRoles.freemium && (eventdata.loginUserStatus !== "Manager" ||
      eventdata.loginUserStatus !== "Attending") && eventdata.circleId === null) {
      navigateToGetPremium();
    } else {
      setMembers([]);
      setInput(inputs);
      setInput(prevState => ({
        ...prevState,
        searchInput: null,
      }));
      fetchMore({
        variables: {
          pageNumber: parseInt(1),
        },
      });
      setIsShowFilter(!isShowFilter);
    }
  };
  const onSearch = searchData => { };
  const onFocus = () => {
    if (userRole === userRoles.freemium && (eventdata.loginUserStatus !== "Manager" ||
      eventdata.loginUserStatus !== "Attending") && eventdata.circleId === null) {
      navigateToGetPremium();
    }
  }
  const onUserSelection = userName => {
    setMembers([]);
    // console.log("userName", userName);
    // const name = userName.replace(" ", "", "g");
    setInput(prevState => ({
      ...prevState,
      searchInput: userName,
      location: null,
      industries: null,
      skills: null,
      maxDistance: null,
      collaborationType: null,
      businessType: null,
      pageNumber: parseInt(1),
    }));
  };

  const refresh = () => {
    setInput(prevState => ({
      ...prevState,
      pageNumber: parseInt(1),
    }));
  };
  const onReset = () => {
    setMembers([]);
    const variable = {
      //circleId: circledata.id,
      userId: userId,
      pageNumber: 1,
      pageSize: parseInt(100),
      searchInput: null,
      skills: null,
      industries: null,
      collaborationType: null,
      businessTypes: null,
      location: null,
      maxDistance: null,
    };
    setInput(variable);
  };

  return (
    <Grid container gap={ThemeConstants.custom.spacings.md}>
      <Grid container gap={4}>
        {(eventdata.loginUserStatus === "Manager" || userId == globals.state.admin.userId) && 
          <Accordion summary="ManagerInfoSummary" details="ManagerInfoDetails"/>}
        <Grid item sm={6} xs={9}>
          <VisitorAutosearchPage
            eventDetails={eventdata}
            onSearch={onSearch}
            onFocus={onFocus}
            onUserSelection={onUserSelection}
            placeHolder={intl.formatMessage({
              id: "CircleMember_SearchBar_Text",
            })}
          />
        </Grid>
        <Grid item sm={1} xs={1}>
          <FilterAltOutlinedIcon
            className={classes.iconFilter}
            onClick={showFilter}
          />
        </Grid>
        {(input.businessType != null && input.businessType.length) ||
          (input.collaborationType != null && input.collaborationType.length) ||
          (input.skills != null && input.skills.length) ||
          (input.industries != null && input.industries.length) ||
          input.location != null ||
          input.maxDistance != null ? (
          <Grid item sm={1} xs={2}>
            {/* <RestartAltIcon className={classes.iconReset} onClick={onReset} /> */}
            <Typography
              component="h5"
              variant="h5"
              className={classes.iconReset}
              onClick={onReset}
            >
              Resetten
            </Typography>
          </Grid>
        ) : null}
        <Grid item sm={12} xs={12}>
          {error ? (
            <Alert severity="error">
              {error.length ? (
                error
              ) : (
                <FormattedMessage id={"somethingWentWrong"} />
              )}
            </Alert>
          ) : null}
        </Grid>
      </Grid>
      {isShowFilter ? (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <Accordian onSearch={filterAction} isBrandColor={true} />
          </Grid>
        </Grid>
      ) : loading && !members.length ? (
        <Box
          sx={{
            position: "relative",
            display: "inline-flex",
            alignItems: "center",
            flex: "row",
            justifyContent: "center",
            width: "100%",
            height: 300,
          }}
        >
          <Stack
            spacing={2}
            direction="row"
            justifyContent={"flex-end"}
            alignItems={"center"}
          >
            <CircularProgress />{" "}
          </Stack>
        </Box>
      ) : members.length > 0 ? (
        members.map((memberdata, index) => (
          <VisitorCard
            data={memberdata}
            eventDetails={eventdata}
            onRefresh={refresh}
            key={index}
          >
            {index === members.length - 2 && (
              <Waypoint
                scrollableAncestor={window}
                onEnter={() => {
                  // console.log("index", index);
                  const newPage = input["pageNumber"] + 1;
                  // console.log("newPage", newPage);
                  setInput(prevState => ({
                    ...prevState,
                    pageNumber: newPage,
                  }));
                }}
              />
            )}
          </VisitorCard>
        ))
      ) : (
        <Grid item xs={12} sm={12}>
          <Paper elevation={3} className={classes.tabcontent}>
            <Grid container spacing={4}>
              <Box
                sx={{
                  position: "relative",
                  display: "inline-flex",
                  alignItems: "center",
                  flex: "row",
                  justifyContent: "center",
                  width: "100%",
                  height: 200,
                }}
              >
                <Stack
                  spacing={2}
                  direction="row"
                  justifyContent={"flex-end"}
                  alignItems={"center"}
                >
                  <Typography
                    component="span"
                    variant="h3"
                    color={"textPrimary"}
                  >
                    <FormattedMessage id={"noDataAvailable"} />
                  </Typography>
                </Stack>
              </Box>
            </Grid>
          </Paper>
        </Grid>
      )}
    </Grid>
  );
}
