import React, { useEffect } from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Collapse, List, makeStyles } from '@material-ui/core';
import PropTypes from "prop-types";
import styles from "../styles/component/MenuStyle";
import { CircularProgress, Grid, ThemeProvider, useTheme } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { updateBrandColor } from '../apiServices/Redux/Reducer';
import theme from '../styles/container/Theme';
import { useNotificationCount } from '../apiServices/CommonDataObjects';
import Theme_WIP, { ThemeConstants } from '../styles/themes/main/Theme_WIP';
import Text from '../visualComponents/Text';
import { useHasCompanyAccess } from '../apiServices/Queries/Profiles/MetaData';
import { useGlobalContext } from '../apiServices/Providers/AppGlobalStateProvider';
import {ReactComponent as CirclesIcon} from "../svg/HeaderNavigationEtc/CirclesIcon.svg";
import {ReactComponent as QuestionMark} from "../svg/ui/questionMark.svg";
import { SUPPORT_PAGE_URL } from '../Config';

const useStyles = makeStyles(styles);
export default function ListItems(props) {
    const globals = useGlobalContext();
    const userId  = globals.state.loggedInUser.userId;
    const dispatch = useDispatch()
    const {data: notifications, updateVariables: refetchNotifications} = useNotificationCount();
    const [notifications_, setNotifications_] = React.useState(notifications);
    const classes = useStyles();
    const history = useHistory()
    const circles = globals.state.loggedInUser.circles;
    const admin   = globals.state.admin.userId;
    const [open] = React.useState(true);
    const companyAccess = useHasCompanyAccess();

    useEffect(() => {
        setNotifications_(notifications);
        localStorage.setItem("notifications", notifications);
    }, [notifications]);

    const { routes } = props;
    return (
        <ThemeProvider theme={Theme_WIP}>
            {routes.filter(props => props.displayInDrawer).map((prop, key) => {
                return (
                    <ListItem key={key} button onClick={() => {
                        dispatch(updateBrandColor(theme.palette.primary.main));
                        refetchNotifications();
                        history.push(prop.root + prop.path);
                    }}>
                        <ListItemText primary={
                            <Grid ml="15px" item container gap={ThemeConstants.custom.spacings.md}>
                            {prop.icon ?? null}
                            <Text id={prop.localisationId} variant="body1">
                            {prop.path == "/todos" && notifications_ ? 
                            <span style={{
                                backgroundColor: Theme_WIP.palette.notification.main, 
                                color: Theme_WIP.palette.notification.contrastText,
                                marginLeft: 15,
                                fontSize: 12,
                                fontWeight: Theme_WIP.typography.fontWeightBold,
                                padding: "5px 9px", 
                                borderRadius: "100%", 
                                aspectRatio: "1 / 1"}}>{notifications_ > 9 ? "10+" : notifications_}</span> 
                            : null}
                            </Text>
                            </Grid>
                        } className={classes.icons} />
                    </ListItem>
                );
            })}

            <ListItem button  onClick={() => {
                    dispatch(updateBrandColor(theme.palette.primary.main));
                    history.push('/dashboard/circles');
                }} >
                <Grid ml="15px" item container gap={ThemeConstants.custom.spacings.md}>
                    <CirclesIcon/>
                    <ListItemText primary={<Text variant="body1" id="Side_menu.Circles"/>} className={classes.icons}/>
                </Grid>
                {circles == undefined &&
                    <ListItemIcon className={classes.icons} >
                        <CircularProgress size={20} color={"secondary"} />
                    </ListItemIcon> }
            </ListItem>

            <Collapse in={open} timeout="auto" unmountOnExit>
                {userId != admin ?
                    <List component="div" disablePadding>
                        { circles &&
                            circles.map((circle, index) => (
                                <ListItem button className={classes.nested} key={index} onClick={() => {
                                    const reload = window.location.pathname.startsWith("/dashboard/circleDetails");
                                    dispatch(updateBrandColor(circle.brandColor ?? theme.palette.primary.main))
                                    // history.push('/dashboard/circleDetails', {
                                    //     circleDetails: circle, selectedTab: 0
                                    // });
                                    history.push("/dashboard/circleDetails/?id=" + circle.id, {
                                        selectedCircleId: circle.id,
                                        selectedTab: 3,
                                    });
                                    if(reload)  //TODO: used as a quickfix cause i have no clue why the update depth gets exhausted
                                        window.location.reload();
                                }} >
                                    <ListItemIcon className={classes.icons} />
                                    <ListItemText primary={
                                        <Text pl={6} id={circle.name.length > 15 ? (circle.name.substring(0, 15) + "...") : circle.name}/>
                                    } className={classes.icons} />
                                </ListItem>
                            ))
                        }
                    </List>
                    : null}

            </Collapse>
            {companyAccess.accessToCompanies &&
                    <ListItem button onClick={() => {
                        dispatch(updateBrandColor(theme.palette.primary.main))
                        history.push("/dashboard/companies");
                    }}>
                        <Grid ml="15px" item container gap={ThemeConstants.custom.spacings.md}>
                            <CirclesIcon/>
                            <ListItemText primary={<Text id="Side_menu.Companies"/>} className={classes.icons} />
                        </Grid>
                    </ListItem>}
            {admin == userId &&
                    <ListItem button onClick={() => {
                        dispatch(updateBrandColor(theme.palette.primary.main))
                        history.push("/dashboard/admin");
                    }}>
                        <ListItemText primary={<Text id="Side_menu.Admin"/>} className={classes.icons} />
                    </ListItem>}

            <ListItem button onClick={() => window.open(SUPPORT_PAGE_URL, "_blank")}>
                <Grid ml="15px" item container gap={ThemeConstants.custom.spacings.md} style={{bottom: "30px", position: "fixed", maxWidth: "fit-content"}}>
                    <QuestionMark/>
                    <ListItemText primary={<Text id="Helpdesk"/>} className={classes.icons} />
                </Grid>
            </ListItem>
        </ThemeProvider>
    );
}


ListItems.propTypes = {
    routes: PropTypes.arrayOf(PropTypes.object),
};