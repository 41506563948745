//TODO: admin only path
import { Button, Grid, MenuItem, Select, Skeleton, TextField, TextareaAutosize } from "@mui/material";
import Tile from "../../../visualComponents/Tile";
import Text from "../../../visualComponents/Text";
import { useEffect, useState } from "react";
import { useUpdateBlueprints } from "../../../apiServices/Mutations/Tasks";

const taskify = (blueprint) => ({...blueprint, identity: blueprint.id});

function Task(props) {
    const [task,    setTask   ] = useState();
    const [edited,  setEdited ] = useState(false);
    const [deleted, setDeleted] = useState(false);
    if(task == null && props.data != null) {
        setTask(props.data.id ? taskify(props.data) : props.data);
    }

    useEffect(() => {
      if(task != null && JSON.stringify(task) != JSON.stringify({...props.data, identity: task.identity}) && !edited) {
        setEdited(true);
      }
    }, [task])

    const update_ = (updatedTask) => {
        setTask((prevTask) => {
            let updated = { ...prevTask, ...updatedTask};
            props.update(updated);
            return(updated);
        });
    };

    const delete_ = () => {
        setDeleted  (true);
        props.delete(task);
    };

    const color = () => {
        if      (deleted        ) return "rgba(255,0,0,0.1)";
        else if (task.id == null) return "rgba(0,255,0,0.1)";
        else if (edited         ) return "rgba(0,0,0,0.1)";
        else                      return "transparent";
    }

    return(
        task == null ?
        <Grid container item xs={12}>
            <Skeleton width="100%" height="40px"/>
        </Grid>
        :
        <Grid container item xs={12} p={2} gap={2} bgcolor={color()}>
            <Button
            color="error" variant={deleted ? "outlined" : "contained"} disabled={deleted}
            onClick={delete_}>Delete</Button>
            <Select
            disabled     = {deleted}
            onChange     = {(value) => update_({triggerSource: value.target.value})}
            defaultValue = {task.triggerSource}>
                <MenuItem value={"ACCOUNT_REGISTRATION"}>Account registration</MenuItem>
                <MenuItem value={"PARTICIPATE_IN_EVENT"}>Participate in event</MenuItem>
                <MenuItem value={"MEETING_TAKES_PLACE" }>Meeting takes place</MenuItem>
            </Select>
            <TextField
            disabled    = {deleted}
            onChange    = {(value) => update_({daysAfterTrigger: parseInt(value.target.value)})}
            placeholder = {task.daysAfterTrigger}
            type        = "number"/>
            <TextareaAutosize 
            disabled    = {deleted}
            placeholder = {task.payload}
            onChange    = {(value) => update_({payload: value.target.value})}/>
        </Grid>
    );
}

export default function TaskTab() {
    const [mutateCount,       setCount]   = useState(1);
    const [mutateCount_,      setCount_]  = useState(0);
    const [updatedBlueprints, setUpdated] = useState([]);
    const [deletedBlueprints, setDeleted] = useState([]);
    const [tasks,             setTasks]   = useState([]);
    const {data, loading, error, mutate}  = useUpdateBlueprints(updatedBlueprints, deletedBlueprints);

    useEffect(() => {
    }, [tasks])
    

    function addTask() {
        setTasks(tasks.concat(
            [{identity: (Math.random() + 1).toString(36).substring(7), triggerSource: "ACCOUNT_REGISTRATION", id: null}]
        ));
    }

    function submit() {
        setTasks  ([]);
        mutate    (  );
        setUpdated([]);
        setDeleted([]);
        setCount  (mutateCount + 1);
    }

    function deleteTask(task) {
        setUpdated(updatedBlueprints.filter((t) => t.identity != task.identity));
        if(task.id == undefined) {
            setTasks  (tasks            .filter((t) => t.identity != task.identity));
        } else
            setDeleted(deletedBlueprints.concat([task]));
    }
    function updateTask(task) {
        if(updatedBlueprints.find(bp => bp.identity == task.identity))
            setUpdated(updatedBlueprints.map(bp => bp.identity == task.identity ? task : bp ))
        else
            setUpdated(updatedBlueprints.concat([task]));
    }

    function displayTasks() {
        if(error)
            return <Text variant="body" color="red">{error}</Text>
        if(data != null && !loading && data['updateTaskBlueprints'] == null)
            return <Text variant="body" color="red">Something went wrong</Text>

        if(mutateCount != mutateCount_)
            setTimeout(() => setCount_(mutateCount), 10); //updating data is not frame perfect, therefore a tiny delay is needed
        if(!tasks.length && !loading && data != null && mutateCount == mutateCount_)
            setTasks(data.updateTaskBlueprints.map(bp => taskify(bp)));
        return !data? <Task/> : tasks.map(task => 
        <Task 
        data={task} 
        delete={deleteTask}
        update={updateTask}/>);
    }

    return(
        <Grid container p={5}>
        <Tile id="myTile">
        <Grid container item xs={12} gap={6}>
            <Grid item xs={12}>
                <Text item xs={12} variant="h1" bold>Task Blueprints</Text>
                <Text item xs={12} variant="body2">
                    Plan hier alle tasks op basis van triggers. 
                    Per gebruiker word een Scheduled Task aangemaakt worden wanneer deze gebruiker de trigger aanroept. 
                    De task met de laagste interval word als eerst gepland. 
                    Na het uitvoeren van de task op z'n executiedatum, word de volgende task ingepland op basis van welke interval de eerst volgende is. 
                    Dit herhaald tot er geen grotere interval beschikbaar meer is. De eerste task word X aantal dagen na de trigger uitgevoerd, de 
                    eerst volgende task word X dagen na de laaste task uitgevoerd. 
                    Een task met 0 dagen word op de dag van de trigger uitgevoerd.
                    <br/><br/>
                    Je kan de volgende placeholders gebruiken: {"{name}{location}{date}{name_as_url}{url}{other}"}<br/>
                    <b>name</b> - the name/title of the user/event/circle/activity<br/>
                    <b>location</b>  - the location of the user/event/circle/activity<br/>
                    <b>date</b>  - the date of the user/event/circle/activity<br/>
                    <b>name_as_url</b>  - the name/title of the user/event/circle/activity which will lead to the url when clicked<br/>
                    <b>url</b> - the url of the user/event/circle/activity<br/>
                    <b>other</b> - some other information which does not follow any standard convention. Will represent something unique for every trigger.<br/>
                    </Text>
            </Grid>
            <Grid item container xs={12} gap={2}>
                {displayTasks()}


                {loading || error ? null : <Grid item xs={12}>
                    <Button color="lightPetrol" variant="contained" onClick={addTask}>Add</Button>
                </Grid>}
            </Grid>
            {loading || error || !tasks.length ? null : <Grid item xs={12}>
                <Button color="primary" variant="contained" onClick={submit}>Save</Button>
            </Grid>}
            {!new URLSearchParams(window.location.search).get("debug") ? null : 
            <Grid container item xs={12}>
            <Text overflow="scroll" item xs={6}   variant="body2">{JSON.stringify(deletedBlueprints)}</Text>
            <Text overflow="scroll" item xs={6} variant="body2">{JSON.stringify(updatedBlueprints)}</Text></Grid>}
        </Grid>
        </Tile>
        </Grid>
    );
}