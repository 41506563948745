import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { useHistory } from "react-router-dom";
import { CircularProgress, Paper } from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { ButtonDarkBlue } from "../../components/Buttons/ButtonDarkBlue";
import { CssBaseline, Hidden, Icon, Stack } from "@mui/material";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import EventFilter from "../../components/EventTabs/EventFilter";
import EventCard from "../../components/Cards/EventCard";
import { GetUserId } from "../../apiServices/CommonMethods";
import { EventsTabItems } from "../../apiServices/Constants";
import { useDispatch } from "react-redux";
import { updateBrandColor } from "../../apiServices/Redux/Reducer";
import styles from "../../styles/container/EventsPageStyle";
import { Waypoint } from "react-waypoint";
import { FormattedMessage, useIntl } from "react-intl";
import CircleNewsAutosearchPage from "../../components/CicleNewsAutosearchPage";
import { useListEvents } from "../../apiServices/Queries/Events/GetAllEvents";
import { ThemeConstants } from "../../styles/themes/main/Theme_WIP";

const useStyles = makeStyles(styles);
export default function EventPage() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const intl = useIntl();
  const userId = GetUserId();
  const [isShowFilter, setIsShowFilter] = React.useState(false);
  const [allEvents, setAllEvents] = React.useState([]);
  const [eventTabType, setEventTabType] = React.useState(EventsTabItems[0]);

  let [input, setInput] = React.useState({
    pageNumber: 1,
    location: null,
    maxDistance: null,
    eventTags: null,
    operatesIn: null,
    title: null,
    startDate: null,
  });

  const showFilter = () => {
    setIsShowFilter(!isShowFilter);
  };
  const filterAction = (inputs) => {
    // console.log("inuts", inputs);
    setAllEvents([]);
    setInput(inputs);
    setIsShowFilter(!isShowFilter);
  };

  const getCurrentEventData = () => {
    return allEvents;
  };

  const getVariable = () => ({
      hint: eventTabType.type,
      loginProfileId: userId,
      pageSize: 10,
      pageNumber: input["pageNumber"],
      location: input["location"],
      maxDistance: input["maxDistance"],
      eventTags: input["eventTags"],
      title: input["title"],
      operatesIn: input["operatesIn"],
      startDate: input["startDate"],
    });

  const {data: data, loading: loading, updateVariables: refetch } = useListEvents(getVariable());

  React.useEffect(() => {
    if (!data || !data.length)
      return;
    if (input["pageNumber"] === 1)
      setAllEvents(data);
    else
        setAllEvents((prevState) => [
          ...prevState,
          ...data,
        ]);
  }, [data]);
  React.useEffect(() => {
    refetch(getVariable);
  }, [input, eventTabType]);


  const handleChange = (event, newValue) => {
    setInput((prevState) => ({
      ...prevState,
      pageNumber: 1,
    }));
    let filteredTabItems = EventsTabItems.filter((tabItem) => {
      return tabItem.type === newValue;
    });
    setAllEvents([]);
    setEventTabType(filteredTabItems[0]);
  };

  const onReset = () => {
    setAllEvents([]);
    setInput((prevState) => ({
      ...prevState,
      location: null,
      maxDistance: null,
      eventTags: null,
      operatesIn: null,
      pageNumber: 1,
      title: null,
      startDate: null,
    }));
  };

  const onSearch = (searchtext) => {
    const eventname = searchtext.replace(" ", "", "g");
    // console.log("SearchText", eventname);
    setInput((prevState) => ({
      ...prevState,
      title: searchtext,
      pageNumber: parseInt(1),
      hint: eventTabType.type,
      loginProfileId: userId,
      pageSize: 10,
    }));
  };

  return (
    <React.Fragment>
      <CssBaseline />
        <Grid container spacing={4} mt={0}>
          <Grid item sm={5} xs={7}>
            <CircleNewsAutosearchPage
              placeHolder={intl.formatMessage({ id: "EventPage_Search" })}
              onSearch={onSearch}
            />
          </Grid>
          <Grid item sm={1} xs={1}>
            <FilterAltOutlinedIcon
              className={classes.iconFilter}
              onClick={showFilter}
            />
          </Grid>
          <Grid item sm={2} xs={2}>
            {(input.eventTags != null && input.eventTags.length) ||
              (input.operatesIn != null && input.operatesIn.length) ||
              input.location != null ||
              input.maxDistance != null ||
              input.startDate != null ? (
              <Typography
                component="h5"
                variant="h5"
                className={classes.iconReset}
                onClick={onReset}
              >
                <FormattedMessage id={"Resetten"} />
              </Typography>
            ) :
              null}
          </Grid>

          <Grid item sm={4} xs={2}>
            <Hidden mdUp>
              <Icon
                sx={{
                  color: theme.palette.secondary.main,
                  fontSize: 40,
                }}
                onClick={() => {
                  history.push("/eventNew");
                }}
              >
                add_circle
              </Icon>
            </Hidden>

            <Hidden mdDown>
              <ButtonDarkBlue
                onClick={() => {
                  history.push("/eventNew");
                }}
              >
                <FormattedMessage id="AddEvent" />
              </ButtonDarkBlue>
            </Hidden>
          </Grid>
        </Grid>
        {isShowFilter ? (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <EventFilter onSearch={filterAction} />
            </Grid>
          </Grid>
        ) : (
          <>
            <Grid container spacing={4} className={classes.mt5}>
              <Grid item xs={12} sm={12}>
                <Tabs
                  value={eventTabType.type}
                  onChange={handleChange}
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="scrollable auto tabs example"
                  TabIndicatorProps={{
                    style: { background: theme.palette.color2.main },
                  }}
                >
                  {EventsTabItems.map((tabItem) => (
                    <Tab
                      key={tabItem.key}
                      value={tabItem.type}
                      label={intl.formatMessage({
                        id: tabItem.type,
                      })}
                      wrapped
                      className={classes.masterTab}
                    />
                  ))}
                </Tabs>
              </Grid>
            </Grid>

            <Grid container gap={ThemeConstants.custom.spacings.md}>
              <Grid item xs={12} sm={12}>
                {loading && !getCurrentEventData().length ? (
                  <Box
                    sx={{
                      position: "relative",
                      display: "inline-flex",
                      alignItems: "center",
                      flex: "row",
                      justifyContent: "center",
                      width: "100%",
                      height: 300,
                    }}
                  >
                    <Stack
                      spacing={2}
                      direction="row"
                      justifyContent={"flex-end"}
                      alignItems={"center"}
                    >
                      <CircularProgress />
                      <Typography
                        component="h5"
                        variant="h5"
                        className={classes.title}
                      ></Typography>
                    </Stack>
                  </Box>
                ) : getCurrentEventData().length > 0 ? (
                  getCurrentEventData().map((item, index) => (
                    <Paper
                      elevation={0}
                      className={classes.tabcontent}
                      key={index}
                      onClick={() => {
                        // console.log("event selcted", item);
                        // history.push("/dashboard/eventDetails", {
                        //   eventDetails: item,
                        // });
                        history.push("/dashboard/eventDetails/?id=" + item.id, {
                          eventDetails: item,
                        });
                        dispatch(
                          updateBrandColor(
                            item.brandColor ?? theme.palette.primary.main
                          )
                        );
                      }}
                    >
                      <EventCard eventData={item}></EventCard>
                      {index === getCurrentEventData().length - 2 && (
                        <Waypoint
                          scrollableAncestor={window}
                          onEnter={() => {
                            // console.log(
                            //   "Waypointlength",
                            //   getCurrentEventData().length
                            // );
                            // console.log("Waypointindex", index);
                            const newPage = input["pageNumber"] + 1;
                            // console.log("newPage", newPage);
                            setInput((prevState) => ({
                              ...prevState,
                              pageNumber: newPage,
                            }));
                          }}
                        />
                      )}
                    </Paper>
                  ))
                ) : (
                  <Box
                    sx={{
                      position: "relative",
                      display: "inline-flex",
                      alignItems: "center",
                      flex: "row",
                      justifyContent: "center",
                      width: "100%",
                      height: 300,
                    }}
                  >
                    <Typography
                      component="span"
                      variant="h3"
                      color={"textPrimary"}
                    >
                      <FormattedMessage id="noDataAvailable" />
                    </Typography>
                  </Box>
                )}
              </Grid>
            </Grid>
          </>
        )}
    </React.Fragment>
  );
}
