import * as React from "react";
import { Hidden } from "@mui/material";
import Navigationbar from "../components/Navigationbar";
import { CollaborationTypes } from "../apiServices/Constants";
import { useHistory, useLocation } from "react-router-dom";
import { CREATE_MATCHREQUEST } from "../apiServices/mutations";
import { useLazyQuery, useMutation } from "@apollo/client";
import { ClearCache, GetUserId } from "../apiServices/CommonMethods";
import { FormattedMessage, useIntl } from "react-intl";
import { GET_COLLAB } from "../apiServices/Queries";
import { CssBaseline } from "@material-ui/core";
import GoTo from "../apiServices/Navigation/NavigateWithReload";
import FormContainer from "../components/Forms/FormContainer";
import InputSection from "../components/Forms/InputSection";
import Footer from "../components/Footer";
import Accordion from "../visualComponents/Accordion";


export default function MatchRequestNew() {
    const userId = GetUserId();
    const location = useLocation();
    const [userProfile, setUserProfile] = React.useState(location.state?.selectedUser);
    const intl = useIntl();
    const isKnownEachOther = intl.formatMessage({ id: "We_already_know_each_other" });
    const history = useHistory();
    const [input, setInput] = React.useState({
        requestType: isKnownEachOther,
        rquestMessage: "",
        IsKnownPerson: null,
    });
    const [inputError, setInputError] = React.useState({
        requestType: "",
        rquestMessage: "",
    });

    const [createMatchRequest, { data, loading, error }] =
        useMutation(CREATE_MATCHREQUEST, {
            onCompleted: (dataMatch) => {
                ClearCache("profile");
                if (dataMatch.sendMatchRequest.errorCode !== 0 && dataMatch.sendMatchRequest.message === "FreemiumUserCannotAbleToSentMatchRequestToNonFellowCircleMembers") {
                    navigateToGetPremium()
                }
            }
        });
    const navigateToGetPremium = () => {
        GoTo(history, "/GetPremium");
    };
    // const arrayCollabList = RequestCollaborationOfInterestArray.map(
    //   (collab) => collab.itemType
    // );
    // arrayCollabList.push(isKnownEachOther);


    const generateCollabArray = (dataColllabFromServer) => {
        var arrayCollaborations = [];
        if (dataColllabFromServer.businessPartnerCollaborationInterest) {
            // let dataDict = {
            //   ...dataColllabFromServer.businessPartnerCollaborationInterest,
            //   itemType: CollaborationTypes.businessPartners,
            // };
            arrayCollaborations.push(CollaborationTypes.businessPartners);
        }
        if (dataColllabFromServer.investmentCollaborationInterest) {
            // let dataDict = {
            //   ...dataColllabFromServer.investmentCollaborationInterest,
            //   itemType: CollaborationTypes.investment,
            // };
            arrayCollaborations.push(CollaborationTypes.investment);
        }
        if (dataColllabFromServer.coFoundingPartnerCollaborationInterest) {
            // let dataDict = {
            //   ...dataColllabFromServer.coFoundingPartnerCollaborationInterest,
            //   itemType: CollaborationTypes.coFounding,
            // };
            arrayCollaborations.push(CollaborationTypes.coFounding);
        }
        if (dataColllabFromServer.projectPartnerCollaborationInterest) {
            // let dataDict = {
            //   ...dataColllabFromServer.projectPartnerCollaborationInterest,
            //   itemType: CollaborationTypes.projectPartners,
            // };
            arrayCollaborations.push(CollaborationTypes.projectPartners);
        }
        if (dataColllabFromServer.strategicPartnerCollaborationInterest) {
            // let dataDict = {
            //   ...dataColllabFromServer.strategicPartnerCollaborationInterest,
            //   itemType: CollaborationTypes.strategicPartners,
            // };
            arrayCollaborations.push(CollaborationTypes.strategicPartners);
        }
        if (dataColllabFromServer.mentoringCollaborationInterestPayload) {
            // let dataDict = {
            //   ...dataColllabFromServer.mentoringCollaborationInterestPayload,
            //   itemType: CollaborationTypes.mentoring,
            // };
            arrayCollaborations.push(CollaborationTypes.mentoring);
        }
        arrayCollaborations.push(isKnownEachOther);
        return arrayCollaborations;
    };

    // const handleChange = (event, newValues) => {
    //   const {
    //     target: { value },
    //   } = event;
    //   const newValue = newValues.props.value;
    //   if (checkForPurpose(newValue) || newValue === isKnownEachOther) {
    //     setInput((prevState) => ({
    //       ...prevState,
    //       requestType: typeof value === "string" ? value.split(",") : value,
    //     }));
    //     setInputError((prevState) => ({
    //       ...prevState,
    //       requestType: "",
    //     }));
    //   }
    // };

    // const checkForPurpose = (newValue) => {
    //     const arrayFiltered = input["requestType"].filter((value) => {
    //         return value !== intl.formatMessage({ id: "We_already_know_each_other" });
    //     });
    //     return !arrayFiltered.length || input["requestType"].includes(newValue);
    // };
    const updateTextFileds = (event) => {
        const { id, value } = event.target;
        setInput((prevState) => ({
            ...prevState,
            [id]: value,
        }));
        setInputError((prevState) => ({
            ...prevState,
            [id]: "",
        }));
    };
    const getRequestPurpose = (newValue) => {
        // const arrayFiltered = input["requestType"].filter((value) => {
        //   return value !== intl.formatMessage({ id: "We_already_know_each_other" });
        // });
        // return arrayFiltered.length ? arrayFiltered[0] : null;
        return input["requestType"] !== isKnownEachOther ? input["requestType"] : null
    };

    const handleSave = async () => {
        console.log(input)
        let isValid = await checkForErrors();
        
        if (isValid) {
            let variable = {
                isKnownPerson: input["requestType"] === isKnownEachOther,
                message: input["rquestMessage"],
                recipientUserProfileID: userProfile.userId,
                requestType: getRequestPurpose(),
                requesterUserProfileID: userId,
            };
            
            createMatchRequest({
                variables: variable,
            });
        }
    };

    const checkForErrors = async () => {
        setInputError((prevState) => ({
            ...prevState,
            requestType: !input["requestType"] ? "requestTypeError" : "",
            rquestMessage: !input["rquestMessage"].trim() ? "rquestMessageError" : "",
        }));
        return input["rquestMessage"].trim() && input["requestType"].length;
    };

    React.useEffect(() => {
        if (location.state?.selectedUser)
            setUserProfile(location.state.selectedUser);
    }, [location.state]);


  const proceed = () => {
    if (history.location?.state?.referrer)
      history.push(history.location?.state?.referrer, history.location?.state);
    else
      history.go(-2);
  };


    return (
        <React.Fragment>
            <CssBaseline />
            <Navigationbar />
            <FormContainer 
            modalMessage = {data?.sendMatchRequest?.errorCode ===  0 ? <FormattedMessage id={"RequestSentSuccessfully"} /> : undefined}
            onModalClose = {proceed}
            error        = {data?.sendMatchRequest?.errorCode === -1 ? data.sendMatchRequest.message + "wer" : (error ? <FormattedMessage id={"somethingWentWrong"} /> : undefined)}
            title        = {<FormattedMessage id="SendAMatchRequest"/>}
            description  = {<FormattedMessage id="MatchRequestPage_Description" values={{fullName: userProfile?.givenName + " " + userProfile?.familyName}}/>}
            loading      = {loading}
            onSave       = {handleSave}
            disabled     = {!input.rquestMessage.length || !userProfile}
            onCancel     = {proceed}
            saveId       = "Button_text_Send"
            >
                <Accordion summary="MrSum" details="MrDet" values={{br: <br/>, b: (...chunks) => <b>{chunks}</b>}} />
                <InputSection
                type     = "text"
                disabled = {loading || userProfile}
                id       = "master"
                title    = {<FormattedMessage id="MatchRequestPage_ToWho"/>}
                value    = {userProfile?.givenName + " " + userProfile?.familyName}
                onChange = {(newVal) => setInput({...input, role: newVal.target.value})}
                />
                                
                {/* <InputSection
                  type     = "select"
                  disabled = {loading}
                  id       = "purpose"
                  title    = {undefined}//{<FormattedMessage id="PurposeMatchRequest"     />}
                  label    = {<FormattedMessage id="InputLabel_Text_Select" />}
                  onChange = {handleChange}
                  options  = {collabTypes.map((name) => ({key: name, value: name}))}
                  hint     = {inputError["requestType"] ? <FormattedMessage id={"PleaseSelectPurpose"} /> : undefined}
                /> */}

                <InputSection
                type        = "textarea"
                disabled    = {loading}
                id          = "rquestMessage"
                placeholder = {intl.formatMessage({ id: "introduction_Field_input_user_type" })}
                title       = {<FormattedMessage id="writeAMessage"/>}
                onChange    = {updateTextFileds}
                value       = {input["rquestMessage"]}
                hint        = {inputError["rquestMessage"] ? <FormattedMessage id={"pleaseEnterMessage"} /> : undefined}
                max         = {250}
                />
            </FormContainer>
            <Hidden mdUp>
                <Footer />
            </Hidden>
        </React.Fragment>
    );
}
