import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { ButtonDarkBlue } from "../Buttons/ButtonDarkBlue";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import { useHistory } from "react-router";
import { FormattedMessage, useIntl } from "react-intl";
import Styles from "../../styles/component/EventTabs/EventSettingsStyle";
import LocationAutoComplete from "../Autocomplete/LocationAutoComplete";
import {
  masterDataCircleOperatesIn,
  masterDataEventTags,
  userRoles,
} from "../../apiServices/Constants";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Link,
} from "@material-ui/core";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  ClearCache,
  YYYYMMDDTHHMM,
  endYYYYMMDDTHHMM,
  removeItemOnce,
  startYYYYMMDDTHHMM,
  toBase64,
} from "../../apiServices/CommonMethods";
import MenuItem from "@mui/material/MenuItem";
import { Alert, Avatar, FormHelperText, Skeleton } from "@mui/material";
import { UPDATE_EVENT } from "../../apiServices/mutations";
import { useMutation } from "@apollo/client";
import styled from "styled-components";
import { EventsParticipantScope } from "../../apiServices/Constants";
import { EXTERNAL_EVENT_INVITE_TAB } from "../../Config";
import { useGlobalContext } from "../../apiServices/Providers/AppGlobalStateProvider";
import InputSection from "../Forms/InputSection";

const Input = styled("input")({
  display: "none",
});
const useStyles = makeStyles(Styles);

export default function EventSettings(props) {
  const globals  = useGlobalContext();
  const userRole = globals.state.loggedInUser.role.role;
  const userId   = globals.state.loggedInUser.userId;
  const [pressedPublish, setPressedPublish] = React.useState(false);
  const classes = useStyles();
  const history = useHistory();
  const intl = useIntl();
  const [fileSelected, setFileSelected] = React.useState("");
  const [eventInfo, setEventInfo] = React.useState(props?.eventDetails);
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const [imageSize, setImageSize] = React.useState(false);

  const [updateEvent, { data, loading }] = useMutation(UPDATE_EVENT, {
    onCompleted: (data) => {
      ClearCache("event");
      // console.log("data", data);
      if (typeof data !== "undefined" && data.updateEvents.errorCode === 0) {
        setEventInfo(data.updateEvents.result);
        setInitialData(data.updateEvents.result);
        // history.push("/dashboard/eventDetails", {
        //   eventDetails: data.updateEvents.result,
        //   selectedTab: data.updateEvents.result.isInvitationEnabled ? 4 : 0,
        // });
        if(eventInfo.isPublished || !pressedPublish)
          history.push("/dashboard/eventDetails/?id=" + data.updateEvents.result.id, {
            eventDetails: data.updateEvents.result,
            selectedTab: data.updateEvents.result.isInvitationEnabled ? 4 : 0,
          });
        else
          history.push("/eventPublish", {
            input: input,
            fileSelected: fileSelected,
            eventData: eventInfo,
          });
      }
    },
  });

  let [input, setInput] = React.useState({
    title: "",
    id: "",
    quote: "",
    startDate: null,
    endDate: null,
    isOrganizer: "",
    canEveryoneInvite: false,
    logo: "",
    operatesInDesc: "",
    location: "",
    videoLink: "",
    tagsDesc: [],
    description: "",
    website: "",
    ticketingUrl: "",
    isInvitationEnabled: "",
    hasNewsUpdate: "",
    hideVisitorsToPublic: false,
    participantScopeDesc: "",
    shortDescription: "",
    locationPlaceID: "",
  });
  let [inputError, setInputError] = React.useState({
    logo: "",
    title: "",
    isOrganizer: "",
    startDate: "",
    endDate: "",
    operatesInDesc: "",
    participantScopeDesc: "",
    startDateError: "",
    endDateError: "",
  });

  React.useEffect(() => {
    if (typeof props.eventDetails !== "undefined") {
      setEventInfo(props.eventDetails);
      setInitialData(props.eventDetails);
      // console.log("props.eventDetails", props.eventDetails);
    }
  }, [props]);

  const setInitialData = (eventDetails) => {
    setFileSelected(eventDetails.logo);
    setInput((prevState) => ({
      ...prevState,
      title: eventDetails.title,
      id: eventDetails.index,
      quote: eventDetails.quote,
      startDate: eventDetails.startDate
        ? new Date(eventDetails.startDate)
        : null,
      endDate: eventDetails.endDate ? new Date(eventDetails.endDate) : null,
      isOrganizer: eventDetails.isOrganizer ? "Event_organisor" : "Event_attending",
      canEveryoneInvite: eventDetails.canEveryoneInvite,
      logo: eventDetails.logo,
      operatesInDesc: eventDetails.operatesInDesc,
      location: eventDetails.location,
      videoLink: eventDetails.videoLink,
      tagsDesc: eventDetails.tagsDesc,
      description: eventDetails.description,
      website: eventDetails.website,
      ticketingUrl: eventDetails.ticketingUrl,
      isInvitationEnabled: eventDetails.isInvitationEnabled,
      hasNewsUpdate: eventDetails.hasNewsUpdate,
      hideVisitorsToPublic: eventDetails.hideVisitorsToPublic ?? false,
      participantScopeDesc: eventDetails.participantScopeDesc,
      shortDescription: eventDetails.shortDescription,
      locationPlaceID: eventDetails.locationPlaceID,
    }));
  };

  const onLocationChange = (location) => {
    // console.log("onLocationChange", location);
    if (location) {
      setInput((prevState) => ({
        ...prevState,
        location:
          typeof location === "string" || location instanceof String
            ? location
            : location.description,
      }));
      setInput((prevState) => ({
        ...prevState,
        locationPlaceID:
          typeof location === "string" || location instanceof String
            ? input["locationPlaceID"]
            : location.place_id,
      }));
    }
  };
  const isCheckedOperatesIn = (option) => {
    const selectedOperatesIn = input["operatesInDesc"] ?? "";
    if (selectedOperatesIn === option) {
      return true;
    }
    return false;
  };
  const onEventOperatesInSelection = (event) => {
    var newOperatesIn = "";
    if (event.target.checked) {
      newOperatesIn = event.target.name;
    } else {
      newOperatesIn = "";
    }
    // console.log("newOperatesIn", newOperatesIn);
    setInput((prevState) => ({
      ...prevState,
      operatesInDesc: newOperatesIn,
    }));
    setInputError((prevState) => ({
      ...prevState,
      operatesInDesc: "",
    }));
  };
  const isCheckedTag = (option) => {
    const arrayCircleTags = input["tagsDesc"] ?? [];
    if (arrayCircleTags.includes(option)) {
      return true;
    }
    return false;
  };
  const onEventTagSelection = (event) => {
    var newTags = [...input["tagsDesc"]];
    if (event.target.checked) {
      newTags.push(event.target.name);
    } else {
      removeItemOnce(newTags, event.target.name);
    }
    setInput((prevState) => ({
      ...prevState,
      tagsDesc: newTags,
    }));
  };

  const handleChangeSwitch = (event) => {
    setInput((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.checked,
    }));
  };
  const handleChangeFromDate = (newValue) => {
    const newDate   = new Date(newValue);

    setInput((prevState) => ({
      ...prevState,
      startDate: newDate,
    }));
    setInputError((prevState) => ({
      ...prevState,
      startDate: "",
    }));
  };
  const handleChangeToDate = (newValue) => {
    const newDate = new Date(newValue);
    setInput((prevState) => ({
      ...prevState,
      endDate: newDate,
    }));
    setInputError((prevState) => ({
      ...prevState,
      endDate: "",
    }));
  };
  const handleChangeSelect = (event) => {
    setInput((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
    setInputError((prevState) => ({
      ...prevState,
      [event.target.name]: "",
    }));
  };

  const onImageSelection = async (event) => {
    let fileSelect = event.target.files[0];
    if (typeof fileSelect !== "undefined") {
      let imageUrl = await toBase64(fileSelect);
      const maxfilesize = 1024 * 1024; // 1 Mb
      if (event.target.files[0].size < maxfilesize) {
        setImageSize(false);
        setFileSelected(imageUrl);
        setInput((prevState) => ({
          ...prevState,
          logo: URL.createObjectURL(event.target.files[0]),
        }));
        setInputError((prevState) => ({
          ...prevState,
          logo: "",
        }));
      } else {
        setImageSize(true);
        setFileSelected(null);
        setInput((prevState) => ({
          ...prevState,
          logo: "",
        }));
        setInputError((prevState) => ({
          ...prevState,
          logo: "",
        }));
      }
    }
  };
  const handleChangeTextField = (event) => {
    const { id, value } = event.target;
    setInput((prevState) => ({
      ...prevState,
      [id]: value,
    }));
    setInputError((prevState) => ({
      ...prevState,
      [id]: "",
    }));
  };
  const deleteImage = () => {
    setFileSelected(null)
    setInput((prevState) => ({
      ...prevState,
      logo: null,
    }));
  };

  const getVariable = (isPublished) => {
    let variable = {
      title: input["title"],
      id: eventInfo.id,
      quote: input["quote"],
      startDate: input["startDate"],
      endDate: input["endDate"],
      isOrganizer: input["isOrganizer"] == "Event_organisor",
      canEveryoneInvite: input.canEveryoneInvite,
      logo: fileSelected ?? null,
      operatesInDesc: input["operatesInDesc"],
      location: input["location"],
      videoLink: input["videoLink"],
      tagsDesc: input["tagsDesc"],
      description: input["description"],
      website: input["website"],
      ticketingUrl: input["ticketingUrl"],
      isInvitationEnabled: input["isInvitationEnabled"],
      hasNewsUpdate: input["hasNewsUpdate"],
      hideVisitorsToPublic: input["hideVisitorsToPublic"],
      participantScopeDesc: input["participantScopeDesc"],
      loginProfileId: userId,
      profileId: userId,
      shortDescription: input["shortDescription"],
      isPublished: isPublished,
      organizersEmail: null,
      isOrganizerMailSent: eventInfo.isPublished ? true : false,
      locationPlaceID: input["locationPlaceID"],
    };
    // console.log("eventsettins variable", variable);
    return variable;
  };

  const handleSave = async () => {
    let valid = await checkForErrors();
    if (valid) {
      ClearCache("allEvents");
      updateEvent({
        variables: getVariable(false),
      });
    }
  };
  const handlePublish = async () => {
    let valid = await checkForErrors();
    if (valid) {
      ClearCache("allEvents");
      setPressedPublish(true);
      updateEvent({
        variables: getVariable(true),
      });
    }
  };

  const checkForErrors = async () => {
    setInputError((prevState) => ({
      ...prevState,
      title: !input["title"].trim() ? "titleError" : "",
      isOrganizer: !input["isOrganizer"] ? "isOrganizerError" : "",
      startDate:
        !input["startDate"] || inputError["startDateError"]
          ? "startDateError"
          : "",
      endDate:
        !input["endDate"] || inputError["endDateError"] ? "endDateError" : "",
      operatesInDesc: !input["operatesInDesc"] ? "operatesInDescError" : "",
      participantScopeDesc: !input["participantScopeDesc"]
        ? "participantScopeDescError"
        : "",
    }));
    return (
      input["title"].trim() &&
      input["startDate"] &&
      input["endDate"] &&
      input["operatesInDesc"] &&
      input["participantScopeDesc"] &&
      !inputError["endDateError"] &&
      !inputError["startDateError"]
    );
  };

  const onErrorFromDate = (error) => {
    setInputError((prevState) => ({
      ...prevState,
      startDateError: error ? "startDateError" : "",
    }));
  };
  const onErrorToDate = (error) => {
    setInputError((prevState) => ({
      ...prevState,
      endDateError: error ? "endDateError" : "",
    }));
  };
  const isCheckedParticipantScope = (option) => {
    // console.log("option", option);
    const selectedOperatesIn = input["participantScopeDesc"] ?? "";
    if (selectedOperatesIn === option) {
      return true;
    }
    return false;
  };
  const onParticipantScopeSelection = (event) => {
    var newOperatesIn = "";
    if (event.target.checked) {
      newOperatesIn = event.target.name;
    } else {
      newOperatesIn = "";
    }
    // console.log("newOperatesIn", newOperatesIn);
    setInput((prevState) => ({
      ...prevState,
      participantScopeDesc: newOperatesIn,
    }));
    setInputError((prevState) => ({
      ...prevState,
      participantScopeDesc: "",
    }));
  };

  const navigateToCancelEvent = () => {
    // history.push('/dashboard/profile/', {
    //   selectedUserId: activity.userId,
    // }
    // );

    history.push("/cancelEvent", {
      eventData: eventInfo,
    });
  };

  const getEndDate = () => {
    let startDate = new Date(input["startDate"]);
    // return startDate.setHours(startDate.getHours() + 1);
    return startDate.setMinutes(startDate.getMinutes() + 59)
  };
  // console.log('User Role ', userRole);
  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item sm={12} xs={12}>
          {typeof data !== "undefined" && data.updateEvents.errorCode === -1 ? (
            <Alert severity={"error"}>{data.updateEvents.message}</Alert>
          ) : null}
        </Grid>
        <Grid item sm={12} xs={12}>
          <Typography
            component="h3"
            variant="h2"
            color={"textPrimary"}
            className={classes.mt20}
          >
            <FormattedMessage id={"RoleEvent"} />
            *:
          </Typography>
          <Typography
            component="h3"
            variant="h3"
            color={"textPrimary"}
            className={classes.mt7}
          >
            {loading ? (
              <Skeleton variant="rectangular" height={80} />
            ) : (
              <FormControl className={classes.Select}>
                <InputLabel id="demo-simple-select-label">
                  <FormattedMessage id={"DropDownBox_Text_Select"} />
                </InputLabel>
                {/* <Select
                  labelId="demo-simple-select-label"
                  name="isOrganizer"
                  label={intl.formatMessage({
                    id: "DropDownBox_Text_Select",
                  })}
                  onChange={handleChangeSelect}
                  value={
                    input["isOrganizer"]
                      ? intl.formatMessage({
                          id: input["isOrganizer"],
                        })
                      : ""
                  }
                > */}
                <Select
                  labelId="demo-simple-select-label"
                  name="isOrganizer"
                  label={intl.formatMessage({
                    id: "DropDownBox_Text_Select",
                  })}
                  onChange={handleChangeSelect}
                  value={input["isOrganizer"]}
                  style={{
                    fontSize: 14,
                    fontFamily: 'Montserrat', //h2
                    fontWeight: 800,
                  }}
                >
                  {["Event_attending", "Event_organisor"].map((option, index) => (
                    <MenuItem value={option} key={index} style={{
                      fontFamily: 'Montserrat', //h2
                    }}>
                      {/* {option} */}
                      {<FormattedMessage id={option} />}
                    </MenuItem>
                  ))}
                </Select>
                {inputError["isOrganizer"] && (
                  <FormHelperText>
                    <Typography variant="h5" component="span" color="error">
                      {<FormattedMessage id={"Event_setting_option_select"} />}
                    </Typography>
                  </FormHelperText>
                )}
              </FormControl>
            )}
          </Typography>
        </Grid>
        {input["isOrganizer"] == "Event_attending" && (
          <Grid item xs={12} sm={12}>
            <Typography component="h3" variant="h3" className={classes.btxt}>
              <FormattedMessage id={"EventSettings_Description1"} />
            </Typography>
          </Grid>
        )}
        <Grid item xs={12} sm={12} className={classes.mt20}>
          <Typography variant="h1" className={classes.btxt}>
            <FormattedMessage id={"EventSettings_HeaderInformation"} />
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Typography
            component="h3"
            variant="h2"
            color={"textPrimary"}
            className={classes.mt7}
          >
            <FormattedMessage id={"EventImage"} />:
          </Typography>
          <Typography
            component="h3"
            variant="h3"
            color={"textPrimary"}
            className={classes.mt7}
          >
            {loading ? (
              <Skeleton variant="rectangular" height={80} />
            ) : (
              <FormControl variant="filled" className={classes.Select}>
                <label htmlFor="contained-button-file">
                  <Input
                    accept="image/*"
                    id="contained-button-file"
                    multiple
                    type="file"
                    onChange={onImageSelection}
                  />
                  <Avatar
                    sx={{
                      bgcolor: "grey",
                      width: "auto",
                      height: 150,
                      boxShadow: 3,
                      aspectRatio: "16/7"
                    }}
                    alt="Add image"
                    src={
                      input["logo"]
                        ? input["logo"]
                        : "/assets/img/mastermatch_event_default.png"
                    }
                    variant={"square"}
                  />
                </label>
                <Stack
                  direction="row"
                  justifyContent={"space-between"}
                  sx={{ paddingTop: 2, width: 150 }}
                >
                  <label htmlFor="contained-button-file">
                    <Input
                      accept="image/*"
                      id="contained-button-file"
                      multiple
                      type="file"
                      onChange={onImageSelection}
                    />
                    {/* <Button
                      variant="href"
                      component="span"
                      size="small"
                      color="default"
                      className={classes.buttonImage}
                    > */}
                    <Typography
                      component="span"
                      variant="h5"
                      color={"textSecondary"}
                      className={classes.p15}
                    >
                      <FormattedMessage id={"edit"} />
                    </Typography>
                    {/* </Button> */}
                  </label>
                  <label htmlFor="contained-button-file">
                    <Typography
                      component="span"
                      variant="h5"
                      color={"textSecondary"}
                      className={classes.p15}
                      onClick={deleteImage}
                    >
                      <FormattedMessage id={"delete"} />
                    </Typography>
                  </label>
                  {/* <Button
                    variant="href"
                    component="span"
                    size="small"
                    color="default"
                    className={classes.buttonImage}
                    onClick={deleteImage}
                  >
                    <Typography
                      component="span"
                      variant="h5"
                      color={"textSecondary"}
                      className={classes.p15}
                    >
                      <FormattedMessage id={"delete"} />
                    </Typography>
                  </Button> */}
                </Stack>
                {imageSize ? (
                  <Typography
                    component="span"
                    variant="h6"
                    color={"error"}
                    className={classes.p15}
                  >
                    <FormattedMessage id={"File_Upload_Size_Error_Message"} />
                  </Typography>
                ) : null}
              </FormControl>
            )}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Typography
            component="h3"
            variant="h2"
            color={"textPrimary"}
            className={classes.mt7}
          >
            <FormattedMessage id={"EventSettings_EventName"} />
            *:
          </Typography>
          <Typography
            component="h3"
            variant="h3"
            color={"textPrimary"}
            className={classes.mt7}
          >
            {loading ? (
              <Skeleton variant="rectangular" height={40} />
            ) : (
              <FormControl variant="filled" className={classes.Select}>
                <TextField
                  id="title"
                  // label="Type here"
                  variant="filled"
                  size="small"
                  value={input["title"]}
                  onChange={handleChangeTextField}
                  inputProps={{ maxLength: 50 }}
                />
                {inputError["title"] && (
                  <FormHelperText>
                    <Typography variant="h5" component="span" color="error">
                      <FormattedMessage id={"Event_name_Error"} />
                    </Typography>
                  </FormHelperText>
                )}
              </FormControl>
            )}
          </Typography>
        </Grid>
        {/* <Grid item xs={12} sm={12}>
          <Typography
            component="h3"
            variant="h2"
            color={"textPrimary"}
            className={classes.mt10}
          >
            <FormattedMessage id={"EventSettings_ExtraQuote"} />:
          </Typography>
          <Typography
            component="h3"
            variant="h3"
            color={"textPrimary"}
            className={classes.mt7}
          >
            <FormControl variant="filled" className={classes.Select}>
              <TextField
                id="quote"
                // label="Type here"
                variant="filled"
                size="small"
                value={input["quote"]}
                onChange={handleChangeTextField}
                inputProps={{ maxLength: 100 }}
              />
            </FormControl>
          </Typography>
        </Grid> */}
        {/* <Grid item xs={12} sm={12}>
          <Typography
            component="h3"
            variant="h2"
            color={"textPrimary"}
            className={classes.mt10}
          >
            <FormattedMessage id={"EventSettings_ShortDescription"} />:
          </Typography>
          <Typography
            component="h3"
            variant="h3"
            color={"textPrimary"}
            className={classes.mt7}
          >
            <FormControl variant="filled" className={classes.Select}>
              <TextField
                id="shortDescription"
                // label="Type here"
                variant="filled"
                size="small"
                value={input["shortDescription"]}
                onChange={handleChangeTextField}
                inputProps={{ maxLength: 100 }}
              />
            </FormControl>
          </Typography>
        </Grid> */}
        <Grid item xs={12} sm={12}>
          <Typography
            component="h3"
            variant="h2"
            color={"textPrimary"}
            className={classes.mt10}
          >
            <FormattedMessage id={"Date_time"} />
            *:
          </Typography>
        </Grid>
        <InputSection
          title    = {<FormattedMessage id={"EventSettings_From"} />}
          props    = {{mt: 4, xs:12, sm:6}}
          disabled = {loading}
          type     = "datetime"
          color    = "white"
          min      = {input["endDate"]   ? startYYYYMMDDTHHMM(input["endDate"  ]) : YYYYMMDDTHHMM(new Date())}
          max      = {input["endDate"]   ? endYYYYMMDDTHHMM(input["endDate"  ]) : undefined}
          value    = {input["startDate"] ? YYYYMMDDTHHMM   (input["startDate"]) : null}
          onChange = {handleChangeFromDate}
          hint     = {inputError["startDate"] && <FormattedMessage id="Event_FromTime_Validation"/>}
        />
        <InputSection
          title    = {<FormattedMessage id={"EventSettings_To"} />}
          props    = {{mt: 4, xs:12, sm:6}}
          disabled = {loading}
          type     = "datetime"
          color    = "white"
          min      = {input["startDate"] ? YYYYMMDDTHHMM   (input["startDate"]) : YYYYMMDDTHHMM(new Date())}
          max      = {input["startDate"] ? endYYYYMMDDTHHMM(input["startDate"]) : undefined}
          value    = {input["endDate"  ] ? YYYYMMDDTHHMM   (input["endDate"  ]) : null}
          onChange = {handleChangeToDate}
          hint     = {inputError["endDate"] && <FormattedMessage id="Event_ToTime_Validation"/>}
        />

        <Grid item xs={12} sm={12} className={classes.mt20}>
          <Typography variant="h1" className={classes.btxt}>
            <FormattedMessage id={"EventSettings_InfoSection"} />
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Typography
            component="h3"
            variant="h2"
            color={"textPrimary"}
            className={classes.mt10}
          >
            <FormattedMessage id={"EventSettings_PlaceToBe"} />
            *:
          </Typography>
          {loading ? (
            <Skeleton variant="rectangular" height={100} />
          ) : (
            <Typography
              component="h3"
              variant="h3"
              color={"textSecondary"}
              className={classes.mt7}
            >
              <FormGroup>
                {masterDataCircleOperatesIn().map((option, index) => (
                  <Typography variant="h6" color={"textSecondary"} key={index}>
                    <FormControlLabel
                      control={
                        <Checkbox checked={isCheckedOperatesIn(option.key)} />
                      }
                      label={option.key}
                      onChange={onEventOperatesInSelection}
                      name={option.key}
                      style={{
                        fontFamily: 'Montserrat', //h2
                      }}
                    />
                  </Typography>
                ))}
                {inputError["operatesInDesc"] && (
                  <FormHelperText>
                    <Typography variant="h5" component="span" color="error">
                      <FormattedMessage id={"Event_setting_option_select"} />
                    </Typography>
                  </FormHelperText>
                )}
              </FormGroup>
            </Typography>
          )}
        </Grid>
        {(input["operatesInDesc"] === "Offline" ||
          input["operatesInDesc"] === "Fysiek event" ||
          input["operatesInDesc"] === "Fysiek" ||
          input["operatesInDesc"] === "Hybride" ||
          input["operatesInDesc"] === "Hybrid") && (
            <Grid item xs={12} sm={12}>
              <Typography
                component="h3"
                variant="h2"
                color={"textPrimary"}
                className={classes.mt10}
              >
                <FormattedMessage id={"Location"} />:
              </Typography>
              <Typography
                component="h3"
                variant="h3"
                color={"textPrimary"}
                className={classes.mt7}
              >
                {loading ? (
                  <Skeleton variant="rectangular" height={40} />
                ) : (
                  <FormControl variant="filled" className={classes.Select}>
                    <LocationAutoComplete
                      onChange={onLocationChange}
                      value={input["location"]}
                      placeHolder={"Location"}
                    />
                  </FormControl>
                )}
              </Typography>
            </Grid>
          )}
        {(input["operatesInDesc"] === "Online" ||
          input["operatesInDesc"] === "Online event" ||
          input["operatesInDesc"] === "Hybride" ||
          input["operatesInDesc"] === "Hybrid") && (
            <Grid item xs={12} sm={12}>
              <Typography
                component="h3"
                variant="h2"
                color={"textPrimary"}
                className={classes.mt10}
              >
                <FormattedMessage id={"EventSettings_VideoLink"} />:
              </Typography>
              <Typography
                component="h3"
                variant="h3"
                color={"textPrimary"}
                className={classes.mt7}
              >
                {loading ? (
                  <Skeleton variant="rectangular" height={40} />
                ) : (
                  <FormControl variant="filled" className={classes.Select}>
                    <TextField
                      id="videoLink"
                      label={
                        <FormattedMessage id={"EventSettings_Video_AddUrl"} />
                      }
                      variant="filled"
                      size="small"
                      value={input["videoLink"]}
                      onChange={handleChangeTextField}
                    />
                  </FormControl>
                )}
              </Typography>
            </Grid>
          )}

        <Grid item sm={12} xs={12}>
          <Typography component="h3" variant="h2" color={"textPrimary"}>
            <FormattedMessage id={"EventSettings_SelectEventTags"} />:
          </Typography>
          <Typography
            component="h3"
            variant="h3"
            color={"textPrimary"}
            className={classes.mt7}
          >
            {loading ? (
              <Skeleton variant="rectangular" height={40} />
            ) : (
              <FormControl className={classes.Select}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>
                      <FormattedMessage id={"EventSettings_Select"} />
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <FormGroup>
                        {masterDataEventTags().map((option, index) => (
                          <Typography
                            variant="h6"
                            color={"textSecondary"}
                            key={index}
                          >
                            <FormControlLabel
                              control={
                                <Checkbox checked={isCheckedTag(option.key)} />
                              }
                              label={option.key}
                              onChange={onEventTagSelection}
                              name={option.key}
                            />
                          </Typography>
                        ))}
                      </FormGroup>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </FormControl>
            )}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Typography
            component="h3"
            variant="h2"
            color={"textPrimary"}
            className={classes.mt10}
          >
            <FormattedMessage id={"EventSettings_AboutTheEvent"} />:
          </Typography>
          <Typography
            component="h3"
            variant="h3"
            color={"textSecondary"}
            className={classes.mt7}
          >
            <FormattedMessage id={"EventSettings_Description2"} />
          </Typography>
          <Typography
            component="h3"
            variant="h3"
            color={"textPrimary"}
            className={classes.mt7}
          >
            {loading ? (
              <Skeleton variant="rectangular" height={40} />
            ) : (
              <FormControl variant="filled" className={classes.Select}>
                <TextareaAutosize
                  variant="filled"
                  id="description"
                  aria-label="minimum height"
                  minRows={5}
                  //maxLength={400}
                  // placeholder="Type..."
                  className={classes.txtarea}
                  value={input["description"]}
                  onChange={handleChangeTextField}
                />
              </FormControl>
            )}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Typography
            component="h3"
            variant="h2"
            color={"textPrimary"}
            className={classes.mt10}
          >
            <FormattedMessage id={"EventSettings_EventWebsite"} />:
          </Typography>
          <Typography
            component="h3"
            variant="h3"
            color={"textPrimary"}
            className={classes.mt7}
          >
            {loading ? (
              <Skeleton variant="rectangular" height={40} />
            ) : (
              <FormControl variant="filled" className={classes.Select}>
                <TextField
                  id="website"
                  label={<FormattedMessage id={"EventSettings_AddUrl"} />}
                  variant="filled"
                  size="small"
                  value={input["website"]}
                  onChange={handleChangeTextField}
                />
              </FormControl>
            )}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Typography
            component="h3"
            variant="h2"
            color={"textPrimary"}
            className={classes.mt10}
          >
            <FormattedMessage id={"EventSettings_Registrate_BuyTickets"} />:
          </Typography>
          <Typography
            component="h3"
            variant="h3"
            color={"textSecondary"}
            className={classes.mt7}
          >
            <FormattedMessage id={"EventSettings_Description3"} />
          </Typography>
          <Typography
            component="h3"
            variant="h3"
            color={"textPrimary"}
            className={classes.mt7}
          >
            {loading ? (
              <Skeleton variant="rectangular" height={40} />
            ) : (
              <FormControl variant="filled" className={classes.Select}>
                <TextField
                  id="ticketingUrl"
                  value={input["ticketingUrl"]}
                  label={
                    <FormattedMessage
                      id={
                        "EventSettings_Registrate_BuyTickets_Placeholder_AddUrl"
                      }
                    />
                  }
                  variant="filled"
                  size="small"
                  onChange={handleChangeTextField}
                />
              </FormControl>
            )}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} className={classes.mt20}>
          <Typography variant="h1" className={classes.btxt}>
            <FormattedMessage id={"EventSettings_Settings"} />
          </Typography>
        </Grid>
        {userRole === userRoles.admin ? (
          <Grid item container sm={12} xs={12}>

            <Grid item sm={10} xs={9}>
              <Typography component="h3" variant="h2" color={"textPrimary"}>
                <FormattedMessage id={"Enable_Invites"} />
              </Typography>
              <Typography
                component="h3"
                variant="h3"
                color={"textSecondary"}
                className={classes.mt7}
              >
                <FormattedMessage id={"Enable_Invites_Description"} />
              </Typography>
            </Grid>
            {/* ): null} */}
            {/* {userRole === userRoles.admin ?( */}
            <Grid item sm={1} xs={1} />
            <Grid item xs={1} sm={1}>
              {loading ? (
                <Skeleton variant="rectangular" height={40} />
              ) : (
                <Typography component="h3" variant="h3">
                  <Switch
                    {...label}
                    checked={input["isInvitationEnabled"]}
                    onChange={handleChangeSwitch}
                    name={"isInvitationEnabled"}
                  />
                </Typography>
              )}
            </Grid>
          </Grid>
        ) : null}


        <Grid item sm={12} xs={12}>
          <Typography component="h3" variant="h2" color={"textPrimary"}>
            <FormattedMessage id={"EventSettings_ChooseEventType"} />
            *:
          </Typography>
        </Grid>
        <Grid item sm={12} xs={12}>
          {loading ? (
            <Skeleton variant="rectangular" height={100} />
          ) : (
            <FormGroup>
              {EventsParticipantScope.map((option, index) => (
                <FormControlLabel
                  control={
                    // <Checkbox checked={isCheckedParticipantScope(option.backendKey)} />
                    <Switch
                      {...label}
                      checked={isCheckedParticipantScope(option.key)}
                    />
                  }
                  label={
                    <Typography
                      component="h3"
                      variant="h3"
                      color={"textPrimary"}
                    >
                      {intl.formatMessage({ id: option.key }) + ": "}
                      <Typography
                        component="h3"
                        variant="h3"
                        color={"textSecondary"}
                        display="inline"
                      >
                        {/* {option.description} */}
                        {intl.formatMessage({ id: option.description })}
                      </Typography>
                    </Typography>
                  }
                  onChange={onParticipantScopeSelection}
                  name={option.key}
                  className={classes.mb10}
                />
              ))}

              {inputError["participantScopeDesc"] && (
                <FormHelperText>
                  <Typography variant="h5" component="span" color="error">
                    <FormattedMessage id={"Event_setting_option_select"} />
                  </Typography>
                </FormHelperText>
              )}
            </FormGroup>
          )}
        </Grid>
        {input["participantScopeDesc"] === "Gast event" &&
          <Grid item container sm={12} xs={12}>
            <Grid item sm={10} xs={9}>
              <Typography component="h3" variant="h2" color={"textPrimary"}>
                <FormattedMessage id={"EventSettings_HideVisitorsToPublic"} />
              </Typography>
              <Typography
                component="h3"
                variant="h3"
                color={"textSecondary"}
                className={classes.mt7}
              >
                <FormattedMessage id={"EventSettings_Description7"} />
              </Typography>
            </Grid>
            <Grid item sm={1} xs={1} />
            <Grid item xs={1} sm={1}>
              {loading ? (
                <Skeleton variant="rectangular" height={40} />
              ) : (
                <Typography component="h3" variant="h3" color={"textPrimary"}>
                  <Switch
                    {...label}
                    checked={input["hideVisitorsToPublic"]}
                    onChange={handleChangeSwitch}
                    name={"hideVisitorsToPublic"}
                  />
                </Typography>
              )}
            </Grid>
          </Grid>
        }
        
        {input.isOrganizer == "Event_organisor" && 
          <InputSection
          title={intl.formatMessage({id: "WhoCanInvite"})}
          type        = "switch"
          //disabled    = {disabled}
          id          = "canEveryoneInvite"
          description = {intl.formatMessage({id: "WhoCanInviteDesc"})}
          onChange    = {(event, newValue) => setInput({...input, canEveryoneInvite: newValue})}
          value       = {input.canEveryoneInvite ?? false}
          props       = {{mt: 4, flexDirection: "row-reverse"}}
          />}
        <Grid item container sm={12} xs={12}>
          <Grid item sm={10} xs={9}>
            <Typography component="h3" variant="h2" color={"textPrimary"}>
              <FormattedMessage id={"EventSettings_EnableNewsUpdates"} />
            </Typography>
            <Typography
              component="h3"
              variant="h3"
              color={"textSecondary"}
              className={classes.mt7}
            >
              <FormattedMessage id={"EventSettings_Description8"} />
            </Typography>
          </Grid>
          <Grid item sm={1} xs={1} />

          <Grid item xs={1} sm={1}>
            {loading ? (
              <Skeleton variant="rectangular" height={40} />
            ) : (
              <Typography component="h3" variant="h3">
                <Switch
                  {...label}
                  checked={input["hasNewsUpdate"]}
                  onChange={handleChangeSwitch}
                  name={"hasNewsUpdate"}
                />
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid item sm={10} xs={12}>
          <Typography component="h3" variant="h2" color={"textPrimary"}>
            <FormattedMessage id={"EventSetting_MensenUitnodigen"} />
          </Typography>
          <Typography
            component="h3"
            variant="h3"
            color={"textSecondary"}
            className={classes.mt7}
          >
            <FormattedMessage id={"EventSettings_Description"} />{" "}
            <Link
              underline="always"
              href={EXTERNAL_EVENT_INVITE_TAB}
              target={"_blank"}
              rel="noopener noreferrer"
              className={classes.btxt}
            >
              <FormattedMessage id={"EventSettings_Verifieren"} />
            </Link>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} className={classes.mt20}>
          <Typography variant="h1" className={classes.btxt}>
            <FormattedMessage id={"EventSettings_SaveAndPublish"} />
          </Typography>
        </Grid>
        <Grid item sm={12} xs={12}>
          <Typography component="h3" variant="h3" color={"textSecondary"}>
            <FormattedMessage id={"EventSettings_Description9"} />
          </Typography>
        </Grid>
        {!eventInfo.isPublished && (
          <Grid item sm={12} xs={12}>
            <Grid item sm={4} xs={12}>
              <Typography component="h3" variant="h3">
                {loading ? (
                  <Skeleton variant="rectangular" height={40} />
                ) : (
                  <ButtonDarkBlue onClick={handleSave}>
                    <FormattedMessage
                      id={"Button_Text_SaveWithoutPublishing"}
                    />
                  </ButtonDarkBlue>
                )}
              </Typography>
            </Grid>
          </Grid>
        )}
        <Grid item sm={12} xs={12}>
          <Grid item sm={4} xs={12}>
            <Typography component="h3" variant="h3">
              {loading ? (
                <Skeleton variant="rectangular" height={40} />
              ) : (
                <ButtonDarkBlue onClick={handlePublish}>
                  <FormattedMessage
                    id={"EventSettings_Button_Text_Publish_Unpublish"}
                  />
                </ButtonDarkBlue>
              )}
            </Typography>
          </Grid>
        </Grid>
        <Grid item sm={12} xs={12}>
          <Grid item sm={4} xs={12}>
            <Typography component="h3" variant="h3">
              {loading ? (
                <Skeleton variant="rectangular" height={40} />
              ) : (
                <Button
                  disableElevation
                  fullWidth
                  variant="contained"
                  style={{
                    backgroundColor: "white",
                    border: "1px solid #bdbdbd",
                    fontSize: 14,
                    fontFamily: 'Montserrat', //h2
                    fontWeight: 800,
                  }}
                  onClick={navigateToCancelEvent}
                >
                  <FormattedMessage
                    id={"eventSettings_Button_Text_CancelEvent"}
                  />
                </Button>
              )}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </div >
  );
}
