import React from "react";
import Text from "../../../visualComponents/Text";
import { ThemeProvider } from "@mui/material";
import Theme_WIP from "../../../styles/themes/main/Theme_WIP";

export default function ActivityMatchRequestAcceptCard(source, color) {
  if(source == "menu")
    return undefined;
  if(source == "canCollapse")
    return undefined;
  return (
    <ThemeProvider theme={Theme_WIP}>
      <Text color={color??"background"} id="YourMatchRequestAccepted" />
    </ThemeProvider>
  );
}
