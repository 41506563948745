import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useHistory, useLocation } from "react-router-dom";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { ButtonBlue } from "../components/Buttons/ButtonBlue";
import { Button, IconButton, Link, Stack, ThemeProvider, CircularProgress, Hidden, CardMedia } from "@mui/material";
import styles from "../styles/container/ActivityPageStyle";
import MatchRequestCard from "../components/Cards/ActivityCards/MatchRequestCard";
import {
  GET_ALL_ACTIVITIES,
  GET_NEW_ACTIVITIES,
  GET_PENDING_ACTIVITIES,
} from "../apiServices/Queries";
import { onlyUnique } from "../apiServices/CommonMethods";
import { useLazyQuery, useMutation } from "@apollo/client";
import { ActivityItems, ActivityStatus, userRoles } from "../apiServices/Constants";
import ActivityMessageCard from "../components/Cards/ActivityCards/ActivityMessageCard";
import ActivityThankyouCard from "../components/Cards/ActivityCards/ActivityThankyouCard";
import ActivityRespondToRequest from "../components/Cards/ActivityCards/ActivityRespondToRequestCard";
import ActivityIntroductionCard from "../components/Cards/ActivityCards/ActivityIntroductionCard";
import ActivitySuggestionCard from "../components/Cards/ActivityCards/ActivitySuggestionCard";
import ActivityLeftCircleCard from "../components/Cards/ActivityCards/ActivityLeftCircleCard";
import ActivityCircleInvite from "../components/Cards/ActivityCards/ActivityCircleInvite";
import ActivityNewsUpdate from "../components/Cards/ActivityCards/ActivityNewsUpdate";
import ActivityCircleContactManager from "../components/Cards/ActivityCards/ActivityCircleContactManager";
import ActivityNewsRespond from "../components/Cards/ActivityCards/ActivityNewsRespond";
import ActivityCirclePostRespond from "../components/Cards/ActivityCards/ActivityCirclePostRespond";
import ActivityCirclePost from "../components/Cards/ActivityCards/ActivityCirclePost";
import ActivityMatchRequestAcceptCard from "../components/Cards/ActivityCards/ActivityMatchRequestAcceptCard";
import ActivitySuggestionResponseCard from "../components/Cards/ActivityCards/ActivitySuggestionResponseCard";
import ActivityEventJoinAsGuest from "../components/Cards/ActivityCards/ActivityEventJoinAsGuest";
import ActivityEventJoinAsGuestRespond from "../components/Cards/ActivityCards/ActivityEventJoinAsGuestRespond";

import ActivityEventInvite from "../components/Cards/ActivityCards/ActivityEventInvite";
import ActivityMakeEventManager from "../components/Cards/ActivityCards/ActivityMakeEventManager";
import ActivityEventCancel from "../components/Cards/ActivityCards/ActivityEventCancel";
import ActivityEventNewsUpdateCard from "../components/Cards/ActivityCards/ActivityEventNewsUpdateCard";
import ActivityEventNewsRespond from "../components/Cards/ActivityCards/ActivityEventNewsRespond";
import { FormattedMessage, useIntl } from "react-intl";
import { Waypoint } from "react-waypoint";
import ActivityCollabRequest from "../components/Cards/ActivityCards/ActivityCollabRequest";
import AlertWithAction from "../components/Alert/AlertWithAction";
import { MARK_AS_DONE_MATCH_REQUEST, UPDATE_FEED_CHECK } from "../apiServices/mutations";
import YesCard from "../components/Cards/ActivityCards/YesCard";
import MatchSuggestionFoundCard from "../components/Cards/ActivityCards/MatchSuggestionFoundCard";
import { useGlobalContext } from "../apiServices/Providers/AppGlobalStateProvider";
import Text from "../visualComponents/Text";
import Theme_WIP, { ThemeConstants } from "../styles/themes/main/Theme_WIP";
import UserPostedCard from "../components/Cards/Defaults/UserPostedCard";

import {ReactComponent as Message} from "../svg/ui/message.svg";
import {ReactComponent as Introduce} from "../svg/ui/introduce.svg";
import {ReactComponent as Meeting} from "../svg/ui/meeting.svg";

const useStyles = makeStyles(styles);

export default function ActivityPage() {
  const globals  = useGlobalContext();
  const userId   = globals.state.loggedInUser.userId;
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const intl = useIntl();
  const location = useLocation();
  const [activityType, setActivityType] = React.useState(ActivityItems[location?.state?.query == "pending" ? 1 : 0]);
  const [activities, setActivities] = React.useState([]);
  const [activitySourceId, setActivitySourceId] = React.useState(location?.state?.activitySourceId ?? null);
  const [activityUserID, setActivityUserID] = React.useState(location?.state?.activityUserID ?? null);
  const [userNameSelected, setUserNameSelected] = React.useState(location?.state?.fullname ?? "");
  const [pageNumber, setPageNumber] = React.useState(1);
  const [updateFeedCheck] = useMutation(UPDATE_FEED_CHECK);
  const [isShowPreferenceAlert, setIsShowPreferenceAlert] = React.useState(false);
  const todos     = location.state?.activityUserID == undefined;
  const [queryType, setQuery] = React.useState(location.state?.query ?? "latest"); //all, pending, latest

  const variables = {
    userId: userId,
    activitySourceId: activitySourceId,
    activityUserID: activityUserID,
    pageNumber: pageNumber,
    pageSize: 10
  };

  const noCardCheck = (activity) =>
     (activity.activity.activityType == "UPDATE"   &&   activity.activity.requesterUserProfileId == userId) 
  || (!todos && activity.userId != userId          &&   activity.userId != location.state?.activityUserID);

  const [fetchActivities, { loading }] = useLazyQuery(
    queryType == "all" ? GET_ALL_ACTIVITIES : (queryType == "pending" ? GET_PENDING_ACTIVITIES : GET_NEW_ACTIVITIES),
    {
      variables: variables,
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        let activities = queryType == "all" ? data?.toAllActivities : (queryType == "pending" ? data?.pendingActivities : data?.latestActivities);
        if (activities && activities?.errorCode === 0) {
          if (pageNumber === 1) {
            setActivities(activities.result);
          } else {
            if (activities.result?.length) {
              setActivities(prevState => [
                ...prevState,
                ...activities.result,
              ]);
            }
          }
        }
        let filtered = activities.result.map((a) => noCardCheck(a) ? undefined : a).filter((x) => x != undefined);
        if(activities.result.length == 10 && (filtered.length < 5 || activities.length < 10) //just trust me on this last part
          && filtered != 10 )
            setPageNumber(pageNumber + 1);
      },
    }
  );

  useEffect(() => {
    if(!location.state?.activityUserID)
      updateFeedCheck();

    setActivities([]);
    setQuery(location.state?.query ?? (location.state?.activityUserID ? "all" : "latest"));
    setUserNameSelected(location.state?.fullname ?? undefined);
    setActivityUserID(location.state?.activityUserID ?? undefined);
    setActivitySourceId(location.state?.activitySourceId ?? undefined);
    setPageNumber(1);
  }, [location, location.state, location.key]);

  useEffect(() => fetchActivities({variables: variables}), [userId, activitySourceId, activityUserID, pageNumber, queryType]);

  const handleChange = (event, newValue) => {
    let filteredActivity = ActivityItems.filter((activity) => {
      return activity.type === newValue;
    });
    // setActivitySourceId(undefined);
    // setActivityUserID  (undefined);
    // setUserNameSelected(undefined);
    //setPageNumber(1);
    history.push("/dashboard/todos", {query: filteredActivity[0]?.type === "Todos" ? "latest" : "pending"});
    //setQuery(filteredActivity[0].type === "Todos" ? "latest" : "pending");
    setActivityType(filteredActivity[0]);
  };
  function navigateToActivity(href) {
    history.push(href, {
      request: {
        userId: activityUserID,
        givenName: userNameSelected.split(" ")[0],
        familyName: userNameSelected.split(" ")[1],
      },
      activity: {
        activity: {
          recipientUserProfileIds: activityUserID,
          requesterUserProfileId: activityUserID
        },
        givenName: userNameSelected.split(" ")[0],
        familyName: userNameSelected.split(" ")[1],
      },
    });
  };
  
  const onRefresh = () => {
    // refetch();
    // refetchPending();
    setPageNumber(1)
    fetchActivities({variables: {...variables, pageNumber: 1}});
  };
  const onRefreshAllActivity = (activity) => { //requires activity to be passed
    //history.push("/dashboard/activity", {activityUserID: activity.userId, fullname: activity.givenName + " " + activity.familyName});
    setActivityUserID(activity.userId);
    setActivitySourceId(activity.userId);
    setUserNameSelected(activity.givenName + " " + activity.familyName)
    setPageNumber(1)
  };

  const loadCard = (activity, source, isLess, color) => {    
    let props = {color: color == "white" ? "background" : color, sent: activity.activity.requesterUserProfileId == userId, activity: activity, source: source, isLess: isLess, onRefresh: onRefresh, AllActivity: () => onRefreshAllActivity(activity)}
    if(noCardCheck(activity))
      return null;
    
    if (activity.activity.activityType === "MATCH_REQUEST")
      return (MatchRequestCard({...props}));
    
    else if (activity.activity.activityType === "RESPONSE_TO_REQUEST" &&  activity.activity.activitySource !== "CIRCLE_NEWS")
      return (ActivityRespondToRequest({...props}));
    
    else if (activity.activity.activityType === "MESSAGE")
      return (ActivityMessageCard({...props}));
    
    else if (activity.activity.activityType === "THANK_YOU")
      return (ActivityThankyouCard({...props}));
    
    else if (activity.activity.activityType === "INTRODUCTION")
      return (ActivityIntroductionCard({...props}));

    else if (activity.activity.activityType === "SUGGESTION")
      return (ActivitySuggestionCard({...props}));

    else if (activity.activity.activityType === "CIRCLE_UPDATE" || activity.activity.activityType === "PROFILE_INVITATION" || activity.activity.activityType === "CIRCLE_CLOSED")
      return (ActivityLeftCircleCard({...props}));
    
    else if (activity.activity.activityType === "CIRCLE_INVITATION")
      return (ActivityCircleInvite({...props}));

    else if (activity.activity.activityType === "CIRCLE_NEWS_UPDATE")
      return (ActivityNewsUpdate({...props}));

    else if (activity.activity.activityType === "CIRCLE_CONTACT_FORM")
      return (ActivityCircleContactManager({...props}));
    
    else if (activity.activity.activityType === "CIRCLE_NEWS_RESPOND")
      return (ActivityNewsRespond({...props}));

    else if (activity.activity.activityType === "CIRCLE_POST")
      return (ActivityCirclePost({...props}));

    else if (activity.activity.activityType === "CIRCLE_POST_RESPOND")
      return (ActivityCirclePostRespond({...props}));

    else if (activity.activity.activityType === "MATCH_REQUEST_ACCEPT")
      return (ActivityMatchRequestAcceptCard({...props}));

    else if (activity.activity.activityType === "SUGGESTION_RESPOND")
      return (ActivitySuggestionResponseCard({...props}));

    else if (activity.activity.activityType === "JOIN_AS_GUEST")
      return (ActivityEventJoinAsGuest({...props}));

    else if (activity.activity.activityType === "JOIN_AS_GUEST_RESPOND")
      return (ActivityEventJoinAsGuestRespond({...props}));

    else if (activity.activity.activityType === "EVENT_INVITATION")
      return (ActivityEventInvite({...props}));

    else if (activity.activity.activityType === "EVENT_UPDATE")
      return (ActivityMakeEventManager({...props}));

    else if (activity.activity.activityType === "EVENT_CLOSED")
      return (ActivityEventCancel({...props}));

    else if (activity.activity.activityType === "EVENT_NEWS_UPDATE")
      return (ActivityEventNewsUpdateCard({...props}));

    else if (activity.activity.activityType === "EVENT_NEWS_RESPOND")
      return (ActivityEventNewsRespond({...props}));

    else if (activity.activity.activityType === "UPDATE")
      return (ActivityCollabRequest({...props}));

    else if (activity.activity.activityType === "YES")
      return (YesCard({...props}));

    else if (activity.activity.activityType === "MATCH_SUGGESTION_FOUND" && todos)
      return (MatchSuggestionFoundCard({...props}));

    return ActivityCircleContactManager({...props});
  };

  const getCurrentActivityList = activities.filter(onlyUnique);

  const handleSucessAlert = async () => {
    setIsShowPreferenceAlert(false)
  };

  const interactionButtons = () => {
    return activityUserID && 
    <Grid container item xs="auto" ml="auto" gap={ThemeConstants.custom.spacings.md}>
      <Hidden mdDown>
        <Button
          variant="contained"
          color="background"
          sx={{path: {fill: ThemeConstants.palette.background.contrastText}}}
          startIcon={
            <Message/>
          }
          onClick={() => navigateToActivity("/sendMessage")}
        ><FormattedMessage id="Reageren" /></Button>
        <Button
          variant="contained"
          color="background"
          sx={{"circle, path, g, mask, svg": {maskType: "alpha", opacity: 1, fill: ThemeConstants.palette.background.contrastText, stroke: ThemeConstants.palette.background.contrastText}}}
          startIcon={
            <Introduce fill={ThemeConstants.palette.background.contrastText} stroke={ThemeConstants.palette.background.contrastText}/>
          }
          onClick={() => navigateToActivity("/introduction")}
        ><FormattedMessage id="Introduceren"/></Button>
        <Button
          variant="contained"
          color="background"
          sx={{path: {fill: ThemeConstants.palette.background.contrastText}}}
          startIcon={
            <Meeting/>
          }
          onClick={() => navigateToActivity("/suggestOneToOne")}
        ><FormattedMessage id="suggest1on1" /></Button>
      </Hidden>
      <Hidden mdUp>
        <IconButton onClick={() => navigateToActivity("/sendMessage")} sx={{p: 0.5}}>
            <Message/>
        </IconButton>
        <IconButton onClick={() => navigateToActivity("/introduction")} sx={{p: 0.5}}>
            <Introduce/>
        </IconButton>
        <IconButton onClick={() => navigateToActivity("/suggestOneToOne")} sx={{p: 0.5}}>
            <Meeting/>
        </IconButton>
      </Hidden>

    </Grid>;
  };

  const renderActivities = getCurrentActivityList.map((activity, index) => 
    noCardCheck(activity) ? undefined :  <ActivityCard
        messageCard = {activityUserID != undefined}
        matchTime = {activity.activity.activityType === "YES" || activity.activity.activityType === "MATCH_SUGGESTION_FOUND"}
        todo      = {activityUserID == undefined} 
        activity  = {activity} 
        loadCard  = {(source, isLess, color) => loadCard(activity, source, isLess, color)} 
        onRefresh = {onRefresh} 
        intl      = {intl} 
        userId    = {userId}/>
  ).filter((x) => x != undefined);

  return (
      <>
        {location.state?.fullname && 
        <ThemeProvider theme={Theme_WIP}>
          <Grid container item xs={12} py={ThemeConstants.custom.spacings.md} my={ThemeConstants.custom.spacings.lg} sx={{borderBottom: "1px solid " + ThemeConstants.palette.lightPetrol.main}}>
              <CardMedia
                component="img"
                image={location.state?.pfp ?? "/assets/img/userProfile.png"}
                alt="profile picture"
                style={{
                    width: "40px",
                    borderRadius: "5px 5px 5px 0px",
                    aspectRatio: "1 / 1",
                    marginRight: ThemeConstants.custom.spacings.md
                }}
              />
            <Text variant="h2" bold sx={{ maxWidth: "50vw", textOverflow: "ellipsis", overflow: "hidden", textWrap: "nowrap"}}><Link underline="always" href={"/dashboard/profile/?id="+activityUserID}>{location.state.fullname}</Link></Text>
            {interactionButtons()}
          </Grid>
        </ThemeProvider>
        }
          <Grid container item>
            {isShowPreferenceAlert ? (
              <AlertWithAction
                message={
                  intl.formatMessage({ id: "preference_permission_error", })
                }
                title={
                  intl.formatMessage({ id: "warning" })}
                handleClose={handleSucessAlert}
                open={true}
              />
            ) : null}
            {/* <Grid item sm={6} xs={8}>
              <Grid container>
                <Stack
                  direction={"row"}
                  sx={{ spacing: 2, alignItems: "center", width: "100%" }}
                >
                  <Grid item sm={12} xs={12}>
                    <ActivityPageUserSearch
                      showLoggedInUser={false}
                      onSearch={onSearch}
                      onUserSelection={onUserSelection}
                      serchTextCheck={searchTextCheck}
                      placeHolder={intl.formatMessage({
                        id: "CircleMember_SearchBar_Text",
                      })}
                    />
                  </Grid>
                </Stack>
              </Grid>
            </Grid> */}
          </Grid>

          {!activityUserID &&
          <Grid container spacing={4} mt={2}>
            <Grid item xs={12} sm={12}>
              <Tabs
                value={activityType.type}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
                TabIndicatorProps={{
                  style: { background: theme.palette.color2.main },
                }}
              >
                {
                  ActivityItems.map((activity) => (
                    <Tab
                      key={activity.key}
                      value={activity.type}
                      label={intl.formatMessage({
                        id: activity.type,
                      })}
                      wrapped
                      className={classes.masterTab}
                    />
                  ))
                }
              </Tabs>
            </Grid>
          </Grid>}

          {(loading) && pageNumber === 1 ? (
            <Box
              sx={{
                position: "relative",
                display: "inline-flex",
                alignItems: "center",
                flex: "row",
                justifyContent: "center",
                width: "100%",
                height: 300,
              }}
            >
              <Stack
                spacing={2}
                direction="row"
                justifyContent={"flex-end"}
                alignItems={"center"}
              >
                <CircularProgress />
              </Stack>
            </Box>
          ) : (
            <Grid container gap={ThemeConstants.custom.spacings.md} className={classes.mt5} sx={{pointerEvents: "none !important"}}>
              {getCurrentActivityList.length ? <>
                {renderActivities}
                <div style={{height: "50px", width: "100%"}}></div>
                <Waypoint
                  scrollableAncestor={window}
                  onEnter={() => {
                    // console.log("index", index);
                    const newPage = pageNumber + 1;
                    // console.log("newPage", newPage);
                    setPageNumber(newPage)
                  }}
                />
                <div style={{height: "50px", width: "100%"}}></div>
              </> : (
                <Box
                  sx={{
                    position: "relative",
                    display: "inline-flex",
                    alignItems: "center",
                    flex: "row",
                    justifyContent: "center",
                    width: "100%",
                    height: 300,
                  }}
                >
                  <Typography
                    component="span"
                    variant="h3"
                    color={"textPrimary"}
                  >
                    <FormattedMessage id={"noDataAvailable"} />
                  </Typography>
                </Box>
              )}
            </Grid>
          )}
      </>
  );
}

export const ActivityCard = ({todo, activity, loadCard, onRefresh, intl, userId, matchTime, messageCard}) => {
  const globals = useGlobalContext();
  const adminId = globals.state.admin.userId;
  const [expanded, setExpanded] = React.useState(true); //list of activity ids
  const collapsable = (activity?.activity?.messages?.length ?? 0) > 150;// loadCard(activity, "canCollapse");
  const activityId  = activity?.activity?.requesterUserProfileId;
  const sent        = activityId == userId;


  const [MarkAsDone, { data: dataMarkAsDone, loading: loadingMarkAsDone }] =
    useMutation(MARK_AS_DONE_MATCH_REQUEST, {onCompleted: onRefresh});
  const markAsDoneMatchRequestAction = async () => {
    if (activity.activity.status !== ActivityStatus.Closed) {
      const variables = {
        activityType: activity.activity.activityTypeDesc,
        activityId: activity.activity.id,
      };
      MarkAsDone({
        variables: variables,
        onCompleted: onRefresh
      });
    }
  };
  const color   = matchTime ? "matchTime" : (sent || adminId == activityId ? "primary" : "white");
  const menu    = loadCard("menu", undefined, undefined);
  const content = loadCard("content", collapsable && !expanded, color);

  return content && <UserPostedCard
    reduceWhiteSpace = {true}
    minimal   = {messageCard}
    id        = {activity.userId}
    label     = {intl.formatMessage({id: activity.activity.activityType})}
    username  = {activity.givenName + " " + activity.familyName}
    pfp       = {activity.profilePictureUrl}
    date      = {activity.activity.updatedDate}
    menuItems = {!sent && menu}
    checked   = {sent ? undefined : activity?.activity?.status === ActivityStatus.Closed }
    onCheck   = {sent || activity?.activity?.status === ActivityStatus.Closed ? undefined : markAsDoneMatchRequestAction}
    onExpand  = {!collapsable ? undefined : () => setExpanded(!expanded)}
    expandedByDefault={!collapsable || expanded}
    props={{border: sent? "bottomRight" : "bottomLeft", color: color, xs: todo ? 12 : 11, marginLeft: sent? "auto" : undefined}}
  >
    {content}
  </UserPostedCard>}