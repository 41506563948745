import React from "react";
import {
  format24Time,
  format24UTCTime,
  getLongDateWithYear,
} from "../../../apiServices/CommonMethods";
import { ActivityStatus } from "../../../apiServices/Constants";
import { useIntl } from "react-intl";
import Link from "@mui/material/Link";
import { ThemeProvider } from "@mui/material";
import Theme_WIP from "../../../styles/themes/main/Theme_WIP";
import Text from "../../../visualComponents/Text";


export default function ActivitySuggestionResponseCard({source, activity, color}) {
  const intl = useIntl();

  const getMessageForAcceptedMeeting = (activitySelected) => {
    const meetings = activitySelected.meetings;
    var index = meetings.findIndex(function (meeting) {
      return meeting.status === "ACCEPT";
    });
    return intl.formatMessage({ id: "AcceptedOption" }) + " " + (index + 1) + intl.formatMessage({ id: "AcceptedOption_Description" });;
  };

  const getSuggestionStatus = () => {
    if (
      activity.activity.statusBeforeClosing === ActivityStatus.oneOnOneAccepted
    ) {
      return getMessageForAcceptedMeeting(activity.activity);
    } else if (
      activity.activity.statusBeforeClosing === ActivityStatus.oneOnOneDeclined
    ) {
      // return "Declined all options";
      return intl.formatMessage({ id: "DeclinedAllOptions" });
    } else if (
      activity.activity.statusBeforeClosing ===
      ActivityStatus.oneOnOneDeclinedAndInitiatedNew
    ) {
      // return "Declined all options and suggested new";
      return intl.formatMessage({ id: "DeclinedAllOptionsAndSuggestedNew" });
    }
  };

  if(source == "menu")
    return undefined;
  if(source == "canCollapse")
    return undefined;
  return (
    <ThemeProvider theme={Theme_WIP}>
      {(activity?.activity?.statusBeforeClosing === ActivityStatus.oneOnOneAccepted ||
        activity?.activity?.statusBeforeClosing === ActivityStatus.oneOnOneDeclined ||
        activity?.activity?.statusBeforeClosing === ActivityStatus.oneOnOneDeclinedAndInitiatedNew) &&
          <Text item xs={12} color={color??"background"}>
            {getSuggestionStatus()}
          </Text>
      }
      {( activity.activity.statusBeforeClosing === ActivityStatus.oneOnOneAccepted 
      || activity.activity.statusBeforeClosing === ActivityStatus.oneOnOneDeclined) &&
        activity.activity.meetings.map(
          (meeting, index) => (meeting.status === "ACCEPT" || meeting.status === "DECLINED") && (
            <Text item xs={12} color={color??"background"}>
              {intl.formatMessage({ id: "Option" }) +
                " " +
                (index + 1) +
                ": " +
                getLongDateWithYear(meeting.meetingDateTime) +
                ", " +
                format24UTCTime(new Date(meeting.meetingDateTime)) +
                ", "}
              <br/>
              {meeting.locationURL !== null && (
                  meeting.meetingType === "OFFLINE" ? (meeting.locationURL) : (
                    <Link
                      color="inherit"
                      href={
                        meeting.locationURL.startsWith("http")
                          ? meeting.locationURL
                          : "https://" + meeting.locationURL
                      }
                      target={"_blank"}
                      rel="noopener noreferrer"
                    >
                      {meeting.locationURL}
                    </Link>
                  )
              )}
            </Text>
          )
        )}
    </ThemeProvider>
  );
}
