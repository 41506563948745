import React from "react";
import { FormattedMessage } from "react-intl";
import Text from "../../../visualComponents/Text";
import { ThemeProvider } from "@mui/material";
import Theme_WIP from "../../../styles/themes/main/Theme_WIP";

export default function ActivityEventCancel({activity, source, color}) {

  if(source == "menu")
    return undefined;
  if(source == "canCollapse")
    return undefined;
  return (
    <ThemeProvider theme={Theme_WIP}>
      {activity.activity &&
        <Text item xs={12} color={color??"background"}>
          {<FormattedMessage id="TheEvent" />}
          {" "} {activity.activity.eventName.toUpperCase()} {" "}
          <FormattedMessage id="CanceledByTheEventManagerYouCanNoLongerAccessTheEvent" />
        </Text>
      }
    </ThemeProvider>
  );
}
